import {parseData} from 'Utils/helpers/SRListHelpers';
import {SortOrder} from 'Utils/enums';
import {RBSG_TENANT_ID} from 'Utils/constants/genericConstants';

export const getMemberResultGridData = (
  tableData,
  sortOrder = SortOrder.DESCENDING,
  currentSortCriteria,
  isSortingEnabled = true,
) => {
  const header = [
    {label: 'External Ref#', value: 'externalRefId'},
    {label: 'Member#', value: 'memberNumber'},
    {label: 'First Name', value: 'firstName'},
    {label: 'Last Name', value: 'lastName'},
    {label: 'Member GUiD', value: 'memberId'},
    {label: 'Package Name', value: 'packageName'},
    {label: 'Postal Code', value: 'postalCode'},
    {label: 'Status', value: 'status'},
  ];

  let hideColumns = {
    externalRefId: RBSG_TENANT_ID.includes(String(tableData?.[0]?.tenantID)) ? true : false,
    memberNumber: !RBSG_TENANT_ID.includes(String(tableData?.[0]?.tenantID)) ? true : false,
    membershipId: true,
    tenantID: true,
    memberId: RBSG_TENANT_ID.includes(String(tableData?.[0]?.tenantID)) ? true : false,
    packageName: !RBSG_TENANT_ID.includes(String(tableData?.[0]?.tenantID)) ? true : false,
  };

  return parseData(
    header,
    tableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hideColumns,
  );
};
