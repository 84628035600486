import React, {useEffect, useState} from 'react';
import Form from 'Components/Form/FormWrapper';
import {buttonList, serviceRequestDetailsSection} from './CommentAndQuerySR';
import {
  createValidationSchema,
  initialValues,
  transcriptInitialState,
} from './commentAndQuerySchema';
import {useDispatch, useSelector} from 'react-redux';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {fetchTranscript} from 'Redux/Slices/serviceRequest';
import {getCurrency, updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {SR_STATUS, UPDATE_SR_ERROR} from 'Utils/constants/genericConstants';
import {getSolicitationId} from 'Utils/helpers/MemberDetails';
import {createSR, updateSR} from 'Redux/Slices/serviceRequest';
import {SnackBar} from 'Components';
import {useSnackBar} from 'Hooks';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import constraints from './CommentAndQueryContraint.json';
import {isDisableFormFields, sortTable} from 'Utils/helpers/SRListHelpers';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {SortOrder} from 'Utils/enums';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {gqlTransactionDashboardSearch} from 'GQL/MethodsBFF/ServiceRequest';
import {v4 as uuidv4} from 'uuid';
import {Box, Button, Paper, Stack, Typography} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import LinkTransactionDialog from 'Components/LinkTransactionDialog';
import TableView from 'Components/TableView';
import {getTRDashboardListGirdData} from 'Pages/TransactionsDashboard/components/TRDashboardListGridDataSource';
import {gqlLinkTransaction} from 'GQL/MethodsBFF/LinkTransaction';

interface IProps {
  srFormDetails?: any;
  closeDialogMethod?: any;
}

const CommentAndQuerySR: React.FC<IProps> = (props) => {
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const loginDetails = useSelector((state: any) => state?.login);

  const dispatch = useDispatch();
  const [formValues, setFormValues]: any = useState(initialValues);
  const [section1FormContent, setSection1FormContent] = useState(serviceRequestDetailsSection);
  const [buttonSection, setButtonSection] = useState(buttonList);
  const [transcript, setTranscript] = useState(transcriptInitialState);
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [srStatus, setSrStatus] = useState('');
  const [isLinkTransactionDisabled, setIsLinkTransactionDisabled] = useState<boolean>(true);
  const [isLinkTransactionDialogOpen, setIsLinkTransactionDialogOpen] = useState(false);
  const [transactionTableData, setTransactionTableData] = useState<any[]>([]);

  const fetchTransactions = async () => {
    let payload = {
      tenantId: loginDetails?.tenantID,
      claimId: selectedSRData?.claimId || props?.srFormDetails?.claimId, //'169', //selectedSR.claimId,
    };
    const headers = {
      'tenant-id': loginDetails?.tenantID,
      // 'tenant-id': 5000005,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };
    let response = await gqlTransactionDashboardSearch(payload, headers);
    let data: any = response.data.getAllClaimedOffer[0].claimedOffers;
    let tempTableData: any = [];

    (data || []).forEach((tableData) => {
      tempTableData.push({
        membership: tableData?.customerId,
        id: tableData.id,
        claimType: tableData.claimType,
        status: tableData.status,
        //owner: tableData?.owner?.userName || '',
        createdDate: dateConversionIsoToLocal(
          tableData?.claimDateTime,
          FORMAT_TYPE.DATE_TIME,
          '',
          tenantConfig?.locale,
        ),
        offerType: tableData.offerType,
        //summary: tableData.summary,
        transactionData: tableData,
      });
    });

    let resp = sortTable(
      tempTableData,
      'membership',
      'membership',
      SortOrder.ASCENDING,
      tenantConfig?.locale,
    );

    setTransactionTableData(resp.tableData);
  };

  useEffect(() => {
    if (props?.srFormDetails?.claimId) {
      fetchTransactions();
      setIsLinkTransactionDisabled(true);
    }
  }, []);

  useEffect(() => {
    setFormContentValues();
    if (selectedSRData && selectedSRData.additionalData) {
      let {additionalData} = selectedSRData;
      let srFormValues = {
        summary: selectedSRData?.summary,
        subType: selectedSRData?.subType,
        subStatus: additionalData?.subStatus,
        channel: additionalData?.channel || 'Phone',
        owner: selectedSRData?.owner?.id || '',
        thirdParty: additionalData?.thirdParty || '',
        thirdPartyPhone: additionalData?.thirdPartyPhone,
        receivedDate: additionalData?.receivedDate
          ? dateConversionIsoToLocal(
              additionalData?.receivedDate,
              null,
              'YYYY-MM-DD',
              tenantConfig?.locale,
            )
          : null,
        transactionAmount: additionalData?.transactionAmount,
        transactionDate: additionalData?.transactionDate
          ? dateConversionIsoToLocal(
              additionalData?.transactionDate,
              null,
              'YYYY-MM-DD',
              tenantConfig?.locale,
            )
          : null,
        statusReason: additionalData?.statusReason,
        comments: additionalData?.comments,
        commentsIntern: additionalData?.commentsIntern,
        buttonAction: '',
      };

      if (selectedSRData.claimId) {
        fetchTransactions();
        setIsLinkTransactionDisabled(true);
      } else {
        setIsLinkTransactionDisabled(false);
      }

      setSrStatus(selectedSRData.status);
      setFormValues(srFormValues);
      setError('');
    } else {
      setSrStatus('');
      setError('');
      setFormValues(initialValues);
    }
  }, [selectedSRData, props.srFormDetails.srType, props.srFormDetails?.srSubType]);

  useEffect(() => {
    (async () => {
      if (
        selectedSRData &&
        selectedSRData.type === 'Membership Services' &&
        selectedSRData.additionalData['transcriptUrl']
      ) {
        try {
          let response = await dispatch(
            fetchTranscript({transcriptUrl: selectedSRData.additionalData['transcriptUrl']}),
          );
          setTranscript({
            enabled: true,
            transcriptData: response.data.getTranscript.transcript,
          });
        } catch (err) {
          console.error(err);
          setTranscript({enabled: false, transcriptData: null});
        } finally {
          //toggleLoader(false);
        }
      } else {
        setTranscript({enabled: false, transcriptData: null});
      }
      return () => {
        setTranscript({enabled: false, transcriptData: null});
      };
    })();
  }, [srStatus, transcript.enabled]);

  const setFormContentValues = () => {
    let {srFormDetails} = props;
    let {additionalData} = selectedSRData ?? {};
    let sortedSubTypes =
      srFormDetails?.srSubType && srFormDetails?.srSubType.length > 0
        ? srFormDetails?.srSubType.slice().sort((a, b) => a - b)
        : [];

    let srSubTypesArray = sortedSubTypes
      ? sortedSubTypes.map((subType) => {
          return {value: subType, text: subType};
        })
      : [];
    let srOwnerListArray = srFormDetails?.srOwnerList
      ? srFormDetails.srOwnerList.map((item) => {
          return {value: item.id, text: item.userName};
        })
      : [];
    let targetFields = {
      subType: {
        targetAttribute: 'options',
        targetValue: srSubTypesArray,
      },

      if(additionalData) {
        targetFields['channel'] = {
          targetAttribute: 'options',
          targetValue: [{value: additionalData?.Channel, text: additionalData?.Channel}],
        };
        targetFields['subStatus'] = {
          targetAttribute: 'options',
          targetValue: [{value: additionalData?.subStatus, text: additionalData?.subStatus}],
        };
      },
      owner: {
        targetAttribute: 'options',
        targetValue: srOwnerListArray,
      },
      thirdParty: {
        targetAttribute: 'options',
        targetValue: [{value: '', text: ''}],
      },
      statusReason: {
        targetAttribute: 'options',
        targetValue: [
          {
            value: additionalData?.statusReason,
            text: additionalData?.statusReason,
          },
        ],
      },
      transactionAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Transaction Amt ' + tenantConfig?.currencySymbol,
      },
    };

    let targetButtonFields = {
      saveButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      pendingButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      closeButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      cancelButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      reopenButton: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
    };
    if (
      selectedSRData?.id &&
      (selectedSRData.status === SR_STATUS.CLOSED || selectedSRData.status === SR_STATUS.CANCELLED)
    ) {
      targetButtonFields = {
        saveButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        pendingButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        closeButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        cancelButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        reopenButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };
    } else if (selectedSRData?.id && selectedSRData.status === SR_STATUS.PENDING) {
      targetButtonFields = {
        saveButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        pendingButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        closeButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        cancelButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        reopenButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };
    }

    let updatedFormContent = updateFormContent(section1FormContent, targetFields);
    setSection1FormContent(updatedFormContent);
    let updateButtonList = updateFormContent(buttonList, targetButtonFields);
    setButtonSection(updateButtonList);

    if (
      selectedSRData?.id &&
      (selectedSRData.status === SR_STATUS.CLOSED || selectedSRData.status === SR_STATUS.CANCELLED)
    ) {
      let disabledFormContent = updateFormContent(
        section1FormContent,
        isDisableFormFields(section1FormContent.fields, true),
      );
      setSection1FormContent(disabledFormContent);
    } else {
      let disabledFormContent = updateFormContent(
        section1FormContent,
        isDisableFormFields(section1FormContent.fields, false),
      );
      setSection1FormContent(disabledFormContent);
    }
  };

  const constraintChecker = {
    member: (constraint) => {
      if (
        constraint.status &&
        constraint.status.toLowerCase() !==
          selectedMember?.currentMemberDetails?.status.toLowerCase()
      ) {
        return {
          isSatisfied: false,
          errorMsg: `The member does not have the required status ${constraint['status']}`,
        };
      }

      return {isSatisfied: true, errorMsg: ''};
    },
    'sr-status': (constraint) => {
      if (constraint.status && constraint.status === srStatus) {
        let isSatisfied = true;
        let errorMsg = '';

        constraint.conditions.every((condition) => {
          let result = constraintChecker[condition.for](condition);
          isSatisfied = result.isSatisfied;
          errorMsg = result.errorMsg;
          return result.isSatisfied;
        });

        return {isSatisfied, errorMsg};
      }

      return {isSatisfied: true, errorMsg: ''};
    },
    activity: (constraint) => {
      if (constraint['activity-name'] && constraint['activity-status']) {
        const {status = ''} =
          selectedSRData.activities.find(
            (activity) => activity.type === constraint['activity-name'],
          ) || {};
        const isSatisfied = status === constraint['activity-status'];
        const errorMsg = isSatisfied
          ? ''
          : `Invalid operation. The activity '${constraint['activity-name']}' should have '${constraint['activity-status']}' status`;
        return {isSatisfied, errorMsg};
      }

      return {isSatisfied: true, errorMsg: ''};
    },
  };

  const isValidRequest = (srType) => {
    let isValid = true;
    let errorMessage = '';
    if (constraints[srType]) {
      constraints[srType].every((constraint) => {
        const {isSatisfied, errorMsg} = constraintChecker[constraint.for](constraint);
        isValid = isSatisfied;
        errorMessage = errorMsg;
        return isSatisfied;
      });
    }
    return {isValid, errorMessage};
  };

  const handleOnSubmit = (values, action) => {
    (async () => {
      let status = srStatus || SR_STATUS.OPEN;
      if (values?.buttonAction) {
        switch (values?.buttonAction) {
          case 'Save':
            status = srStatus || SR_STATUS.OPEN;
            break;
          case 'Pending':
            status = SR_STATUS.PENDING;
            break;
          case 'Close':
            status = SR_STATUS.CLOSED;
            break;
          case 'Cancel':
            status = SR_STATUS.CANCELLED;
            break;
          case 'Reopen':
            status = SR_STATUS.OPEN;
            break;
        }
      }
      let {srType, serviceType} = props.srFormDetails;
      const {isValid, errorMessage} = isValidRequest(srType);
      if (isValid === false) {
        setError(errorMessage);
        return;
      }
      const notificationParams = {
        firstName: selectedMember?.currentMemberDetails?.firstName,
        lastName: selectedMember?.currentMemberDetails?.lastName,
        membershipNumber: selectedMember?.membershipNumber,
        toEmail:
          selectedMember?.currentMemberDetails?.primaryEmail ||
          selectedMember?.currentMemberDetails?.clientEmail,
        packageId: selectedMember?.packageId,
        solicitationId: getSolicitationId(selectedMember),
        externalRefId: selectedMember?.currentMemberDetails?.externalRefId,
        membershipEnabled: tenantConfig.membershipEnabled,
      };

      if (selectedSRData?.id) {
        let updateSRVariable = {
          id: selectedSRData?.id,
          requestObj: {
            commands: [
              {
                commandName: 'updateSR',
                commandId: 'updateSR2',
                type: props.srFormDetails.srType,
                subType: values.subType,
                description: 'description',
                summary: values.summary,
                status: status || SR_STATUS.OPEN,
                owner: {
                  id: values.owner,
                },
                additionalData: {
                  source: 'ng-engage',
                  subStatus: values.subStatus,
                  channel: values.channel,
                  thirdParty: values.thirdParty,
                  thirdPartyPhone: values.thirdPartyPhone,
                  receivedDate: values.receivedDate,
                  transactionAmount: values.transactionAmount,
                  transactionDate: values.transactionDate,
                  statusReason: values.statusReason,
                  comments: values.comments,
                  commentsIntern: values.commentsIntern,
                  service: serviceType,
                  currency: getCurrency(selectedMember),
                },
                audit: {
                  modifiedBy: ownerID?.toLowerCase(),
                  modifiedOn: selectedSRData.audit?.modifiedOn,
                },
              },
            ],
          },
          notificationParams,
        };
        try {
          let resp = await dispatch(updateSR(updateSRVariable)).unwrap();
          let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
          if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
            let payload = {
              id: selectedSRData?.id,
            };
            await dispatch(searchSRByID(payload)).unwrap();
            dispatch(
              isUpdateSRList({
                isRefreshSRList: true,
              }),
            );
            setError('');
            values?.buttonAction !== 'Reopen' && props.closeDialogMethod(false);
          } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
            let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors;
            setError(srUpdateErrors);
          } else console.log('Something Went Wrong');
        } catch (error: any) {
          setError(UPDATE_SR_ERROR);
        }
      } else {
        let apiVariable = {
          serviceRequest: {
            id: null,
            type: srType,
            subType: values.subType,
            member: {
              id: selectedMember?.currentMemberDetails?.memberId,
              membershipId: selectedMember?.membershipId
                ? selectedMember?.membershipId
                : selectedMember?.currentMemberDetails?.memberId,
              // membershipNumber: selectedMember?.membershipNumber || null,
            },
            userId: selectedMember?.currentMemberDetails?.userId || null,
            userGroupId: selectedMember?.membershipId || null,
            owner: {
              id: values.owner,
            },
            description: 'description',
            summary: values.summary,
            status: status || SR_STATUS.OPEN,

            additionalData: {
              source: 'ng-engage',
              subStatus: values.subStatus,
              channel: values.channel,
              thirdParty: values.thirdParty,
              thirdPartyPhone: values.thirdPartyPhone,
              receivedDate: values.receivedDate,
              transactionAmount: values.transactionAmount,
              transactionDate: values.transactionDate,
              statusReason: values.statusReason,
              comments: values.comments,
              commentsIntern: values.commentsIntern,
              service: serviceType,
              currency: getCurrency(selectedMember),
            },
            audit: {
              createdBy: ownerID.toLowerCase(),
            },
          },
          notificationParams,
        };

        let resp = await dispatch(createSR(apiVariable)).unwrap();
        if (resp) {
          if (resp?.data?.createServiceRequest?.id && props?.srFormDetails?.claimId) {
            let payload = {
              srId: resp?.data?.createServiceRequest?.id,
              claimId: props?.srFormDetails?.claimId,
            };
            const headers = {
              'tenant-id': loginDetails?.tenantID,
              // 'tenant-id': 5000005,
              'x-correlation-id': uuidv4(),
              Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
            };
            await gqlLinkTransaction(payload, headers);
          }
          dispatch(
            isUpdateSRList({
              isRefreshSRList: true,
            }),
          );
          props.closeDialogMethod(false);
        }
      }
    })();
  };
  const handleLinkTransactionClick = () => {
    setIsLinkTransactionDialogOpen(true);
  };

  const closeLinkTransactionDialog = () => {
    setIsLinkTransactionDialogOpen(false);
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          onClick={handleLinkTransactionClick}
          disabled={isLinkTransactionDisabled}
          sx={{
            paddingY: globalSpacing.xxs,
            marginY: globalSpacing.s,
            width: '20%',
          }}>
          Link Transaction
        </Button>
      </Box>
      <LinkTransactionDialog
        open={isLinkTransactionDialogOpen}
        onClose={closeLinkTransactionDialog}
        selectedSRId={selectedSRData?.id}
      />
      <Form
        onSubmit={handleOnSubmit}
        validationSchema={createValidationSchema(props.srFormDetails?.isSubTypeMandatory)}
        initialValues={formValues}
        section1={section1FormContent}
        buttonStack={buttonSection}
      />
      <Paper sx={{marginY: globalSpacing.s}}>
        <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
          {transactionTableData && (
            <>
              <Typography variant="h6">Linked Transactions</Typography>
              <TableView
                {...getTRDashboardListGirdData(
                  transactionTableData,
                  SortOrder.DESCENDING,
                  '',
                  true,
                )}
              />
            </>
          )}
        </Stack>
      </Paper>
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
      />
    </>
  );
};
export default CommentAndQuerySR;
