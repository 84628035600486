import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, Divider, Paper, Stack, Typography, IconButton} from '@mui/material';
import {Wrapper, CustomTextInput, NoSearchResults, SnackBar, CustomDatePicker} from 'Components';
import styles from './styles';
import globalSpacing from 'Theme/Spacing';
import {setTenantDetails} from 'Redux/Slices/login';
import {useSnackBar} from 'Hooks';
import {
  // RBSG_TENANT_ID,
  SEARCH_NO_RESULTS_ERROR,
  SR_DASHBOARD_SEARCH_PAGE_SIZE,
  statusList,
} from 'Utils/constants/genericConstants';
import * as Yup from 'yup';
import {useSelector} from 'react-redux';
import {LoadingButton} from '@mui/lab';
import iconSize from 'Theme/Icon';
import {useCSContext} from 'Context/ContentStackContext';
import {useFormik} from 'formik';
import SRDashboardList from './components/SRDashboardList';
import {gqlOwnerDetails} from 'GQL/MethodsBFF/ServiceRequest';
import {getAllMetadata} from 'Redux/Slices/allMetadata';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {srDashboardSearch} from 'Redux/Slices/srDashboardList';
import Loading from 'Components/Loading';
import {
  dateConversionToISOFormatIgnoreTimeZone,
  // dateConversionToISOFormatIgnoreTimeZoneForEndDate,
} from 'Utils/helpers/DateValidation';
import {MEMBER_SEARCH} from 'Pages/SearchMember/searchConstants';

const AdminSRDashboard = () => {
  const [error, setError] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [srOwnerList, setOwnerList]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [srTypeMetadata, setSRTypeMetaData]: any = useState([]);
  const [subTypeMetadata, setSubTypeMetadata]: any = useState({});
  const [srSubTypeList, setSubTypes]: any[] = useState([]);
  const [userMap, setUserMap] = useState({});

  const {artifacts} = useCSContext();
  const [multipleResults, setMultipleResults] = useState<any>();
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const clientArray = useSelector((state: any) => state?.login?.loginInfo?.configs);
  const client = clientArray.map((item) => item['name']);
  const loginDetails = useSelector((state: any) => state?.login);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    client: Yup.string().required(),
  });

  const formik: any = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    initialValues: {
      client: client?.[0],
      id: '',
      srType: '',
      status: [],
      subType: '',
      createdBy: '',
      owner: '',
      startDate: '',
      endDate: '',
    },
    onSubmit: async (values: any) => {
      setMultipleResults(null);
      setButtonLoading(true);
      noResults && setNoResults(false);
      let payload = {
        searchServiceRequestInput: {
          memberId: '',
          membershipId: '',
          id: values['externalId'] ? values['externalId'] : null,
          ownerId: values['owner'],
          type: values['srType'],
          subType: values['subType'],
          status: values.status,
          createdBy: values['createdBy'],
          membershipSubscriptionDate: null,
          paging: {
            pageNumber: 0,
            pageSize: SR_DASHBOARD_SEARCH_PAGE_SIZE,
            orderBy: 'id',
            sortOrder: 'descending',
          },
          dateRange: {
            startDate: dateConversionToISOFormatIgnoreTimeZone(values['startDate']) || null,
            endDate: dateConversionToISOFormatIgnoreTimeZone(values['endDate']) || null,
          },
        },
      };
      let response = await dispatch(srDashboardSearch(payload)).unwrap();
      if (response?.error) {
        setNoResults(true);
        setButtonLoading(false);
      } else if (response && response?.data?.searchSRDashboard) {
        let serviceRequestData = response.data.searchSRDashboard;
        if (
          serviceRequestData?.searchServiceRequestDashBoardOutputDtoList?.length > 0 &&
          serviceRequestData !== null
        ) {
          const usersMappedSRList = mapUsers(
            serviceRequestData?.searchServiceRequestDashBoardOutputDtoList,
          );
          setNoResults(false);
          setMultipleResults(usersMappedSRList);
        } else {
          setNoResults(true);
        }
        //toggleSearchMsg(true);
        setButtonLoading(false);
        setLoading(false);
      } else {
        setError(SEARCH_NO_RESULTS_ERROR);
      }
    },
  });

  const isSearchButtonDisabled = () => {
    const fields = [
      formik.values['externalId'],
      formik.values['owner'],
      formik.values['srType'],
      formik.values['subType'],
      formik.values.status.length > 0,
      formik.values['createdBy'],
      formik.values['startDate'],
      formik.values['endDate'],
    ];

    // Count the number of non-empty fields
    const filledFieldsCount = fields.filter(Boolean).length;

    // Disable if less than two fields are filled
    return filledFieldsCount < 1;
  };

  const createUsersMap = (ownerList) => {
    const usersMap = {};

    (ownerList || []).forEach((owner) => {
      usersMap[owner.id] = owner.userName;
    });

    setUserMap(usersMap);
  };

  const mapUsers = (srList) => {
    let srListData = srList;

    return (srListData || []).map((sr: any) => {
      const ownerId = sr?.additionalData?.owner?.id || sr?.owner?.id;
      // if (ownerId) {
      //   if (!sr?.additionalData?.owner) sr.additionalData.owner = {};
      //   sr.additionalData.owner['userName'] = userMap[ownerId];
      // }
      return {
        ...sr,
        owner: {
          userName: userMap[ownerId],
        },
      };
    });
  };

  useEffect(() => {
    (async () => {
      await dispatch(setTenantDetails(client[0]));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let promisesToMake = [fetchMetaData(), setSROwnerList()];
      let promises = Promise.all(promisesToMake);
      promises.finally(() => {
        setLoading(false);
      });
    })();
  }, [formik.values['client']]);

  const clearMessageOnClose = () => {
    setError('');
  };

  const setSROwnerList = async () => {
    try {
      let headers = {
        'tenant-id': loginDetails?.tenantID,
        // !RBSG_TENANT_ID.includes(loginDetails?.tenantID.toString())
        //   ? loginDetails?.tenantID
        //   : 'internal',
        'user-access-token': loginDetails?.loginInfo?.access_token,
        'client-access-token': loginDetails?.loginInfo?.service_token,
      };
      let response = await gqlOwnerDetails(headers);
      if (response.error) {
        setOwnerList([]);
      } else {
        setOwnerList(response);
        createUsersMap(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMetaData = async () => {
    try {
      let response: any = await dispatch(getAllMetadata({})).unwrap();
      if (response?.error) {
        setError(getErrorMsg(response.error));
      } else {
        let srType: any[] = response?.data?.getAllMetadata;
        let subTypes = {};

        const srTypesMap: Map<string, any> = new Map<string, any>();

        srType.forEach((item) => {
          srTypesMap.set(item.name, item);
          if (!subTypes.hasOwnProperty(item.name)) {
            subTypes[item.name] = item.allowedSubTypes;
          } else {
            subTypes[item.name] = Array.from(
              new Set([...subTypes[item.name], ...item.allowedSubTypes]),
            );
          }
        });

        const srTypes: any[] = Array.from(srTypesMap.values());
        const srTypeList = (srTypes || []).map((srType) => srType?.name).sort();
        setSubTypeMetadata(subTypes);
        setSRTypeMetaData(srTypeList);
        return srTypes;
      }
    } catch (err) {
      console.log(err);
      setError(getErrorMsg(err));
      return err;
    }
  };

  const handleSRTypeChange = (e) => {
    const srType = e.target.value;
    let subType = subTypeMetadata[srType];
    setSubTypes(subType || []);
  };

  if (loading) return <Loading isOpen={true} />;
  return (
    <Wrapper>
      <Paper sx={styles.paper}>
        <Stack spacing={globalSpacing.s} sx={{padding: globalSpacing.m}}>
          <Typography variant="h3">SR Dashboard</Typography>
          <Divider light />
          <Box sx={styles.rowContainer}>
            <CustomTextInput
              label="Client"
              name="client"
              inline
              queryTypesMenu={client}
              onChange={(e) => {
                dispatch(setTenantDetails(e.target.value));
                formik.handleChange(e);
              }}
              value={formik.values['client']}
            />
            <CustomTextInput
              label="SR #"
              inline
              name="externalId"
              queryTypesMenu={srOwnerList}
              onChange={formik.handleChange}
              value={formik.values['externalId']}
            />
            <CustomTextInput
              label="SR Type"
              name="srType"
              inline
              queryTypesMenu={srTypeMetadata}
              onChange={(e) => {
                handleSRTypeChange(e);
                formik.handleChange(e);
              }}
              value={formik.values['srType']}
            />
            <CustomTextInput
              label="Status"
              name="status"
              inline
              isMultipleSelect={true}
              queryStatusMenu={statusList}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values['status']}
            />
          </Box>
          <Box sx={styles.rowContainer}>
            <CustomTextInput
              label="Sub Type"
              name="subType"
              inline
              disabled={srSubTypeList.length > 0 ? false : true}
              queryTypesMenu={srSubTypeList}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values['subType']}
            />
            <CustomTextInput
              label="createdBy"
              name="createdBy"
              inline
              queryTypesMenu={srOwnerList}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values['createdBy']}
            />
            <CustomTextInput
              label="Owner"
              name="owner"
              inline
              queryTypesMenu={srOwnerList}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values['owner']}
            />
          </Box>
          <Box sx={styles.rowContainer}>
            <CustomDatePicker
              label="Start Date"
              date={formik.values['startDate'] || null}
              inline
              position="start"
              handleChange={(startDate) => formik.setFieldValue('startDate', startDate)}
              showTime={true}
              reset={() => formik.setFieldValue(['startDate'], null)}
            />
            <CustomDatePicker
              label="End Date"
              date={formik.values['endDate'] || null}
              inline
              position="start"
              handleChange={(endDate) => formik.setFieldValue('endDate', endDate)}
              showTime={true}
              reset={() => formik.setFieldValue(['endDate'], null)}
            />
          </Box>

          <Box sx={styles.rowContainer}>
            <LoadingButton
              disabled={isSearchButtonDisabled() || buttonLoading}
              onClick={(e) => formik.handleSubmit(e)}
              variant="contained"
              sx={{width: '25%'}}
              loading={buttonLoading}>
              Search
            </LoadingButton>
            <IconButton
              color="primary"
              aria-label="Reset Form"
              onClick={(e) => {
                formik.resetForm({});
                multipleResults && setMultipleResults(null);
                noResults && setNoResults(false);
              }}
              sx={{paddingY: '0rem', marginLeft: globalSpacing.xs}}>
              <img src={artifacts.refresh} style={iconSize} alt="Reset Form" />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      {noResults && <NoSearchResults CSTexts={MEMBER_SEARCH.no_search_result} />}
      {multipleResults && <SRDashboardList results={multipleResults} />}
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
          clearMessageOnClose={clearMessageOnClose}
        />
      )}
    </Wrapper>
  );
};

export default AdminSRDashboard;
