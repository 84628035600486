import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {parseData} from 'Utils/helpers/SRListHelpers';
import {convertToTitleCase} from 'Utils/helpers/StringHelper';

const CREDIT_FLOW = 'credit';

export const getPaymentData = (
  srData,
  tableData,
  sortOrder,
  currentSortCriteria,
  isSortingEnabled,
) => {
  const header = [
    {label: 'Request Id', value: 'billId'},
    {label: 'Related Payment Id', value: 'paymentId'},
    {label: 'Amount', value: 'amount'},
    {label: 'Status', value: 'billStatus'},
    {label: 'Transaction Type', value: 'transactionTypeDesc'},
    {label: 'Flow Type', value: 'flowType'},
    {label: 'Created By', value: 'createdBy'},
    {label: 'Created Date', value: 'createdOn'},
    {label: 'Last Processed Date', value: 'statusDate'},
  ];

  let newTableData: any = [];

  (tableData || []).forEach((data, index) => {
    let paymentAmount = data.amount;
    if (data?.flowType && data?.flowType.toLowerCase() === CREDIT_FLOW) {
      paymentAmount = `-${data.amount}`;
    }
    let paymentsAdditionalData =
      srData.additionalData?.paymentsAdditionalData?.[data.paymentIndex || data.billId] || {};

    let row = {
      billId: data?.billId || data?.requestId || '',
      paymentId: data?.payByCheckFlag
        ? data?.checkDetails?.checkNumber
        : data?.relatedPaymentId
        ? data?.relatedPaymentId
        : '',
      amount: paymentAmount?.toString(),
      billStatus: data?.billStatus
        ? convertToTitleCase(data?.billStatus)
        : data?.status
        ? convertToTitleCase(data?.status)
        : '',
      transactionTypeDesc: data?.transactionTypeDesc || data?.transactionType,
      flowType: convertToTitleCase(data?.flowType) || '',
      createdBy: data?.createdBy,
      createdOn:
        dateConversionIsoToLocal(data?.createdOn, FORMAT_TYPE.DATE_TIME) ||
        dateConversionIsoToLocal(data?.createdDate, FORMAT_TYPE.DATE_TIME) ||
        '',
      statusDate:
        dateConversionIsoToLocal(data?.modifiedOn) ||
        dateConversionIsoToLocal(data?.lastProcessedDate, FORMAT_TYPE.DATE_TIME) ||
        '',
      //hidden fields
      modifiedOn: dateConversionIsoToLocal(data?.modifiedOn, FORMAT_TYPE.DATE_TIME) || '',
      paymentVehicleId: data?.paymentVehicleId,
      paymentCreatedDate: data?.createdOn || '',
      paymentModifiedDate: data?.modifiedOn || '',
      paymentCreatedBy: data?.createdBy,
      paymentModifiedBy: data?.modifiedBy,
      paymentFailureReason: data?.paymentFailureReason,
      paymentStatus: data?.billStatus,
      eftDescription: data?.eftDescriptor || '',
      transactionReason: data?.transactionReason,
      billProcessedDate: data?.billId
        ? dateConversionIsoToLocal(data?.createdOn, FORMAT_TYPE.DATE_TIME) || ''
        : '',
      comments: data?.comments || paymentsAdditionalData?.comments || '',
      paymentIndex: data?.paymentIndex || data?.billId,
      paymentAmount: data?.amount,
    };
    newTableData.push(row);
  });

  const hiddenFields = {
    modifiedOn: true,
    paymentCreatedDate: true,
    paymentModifiedDate: true,
    paymentCreatedBy: true,
    paymentModifiedBy: true,
    paymentFailureReason: true,
    paymentStatus: true,
    eftDescription: true,
    transactionReason: true,
    billProcessedDate: true,
    comments: true,
    paymentIndex: true,
    paymentVehicleId: true,
    paymentAmount: true,
  };

  return parseData(
    header,
    newTableData,
    sortOrder,
    currentSortCriteria,
    isSortingEnabled,
    hiddenFields,
  );
};
