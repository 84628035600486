import {DialogContent} from '@mui/material';
import DialogView from 'Components/DialogView';
import Form from 'Components/Form/FormWrapper';
import SnackBar from 'Components/SnackBar';
import {gqlPaymentCardDetails, gqlChargePayment, gqlUpdatePayment} from 'GQL/MethodsBFF/Payment';
import useSnackBar from 'Hooks/SnackBarErrorHook';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  CURRENCY_ICON,
  PAYMENT_ERROR,
  PAYMENT_METHOD,
  PAYMENT_METHOD_TYPE,
  PAYMENT_STATUS,
  PAYMENT_STATUS_CAPS,
  PAYMENT_UPDATE_FAILED,
  SERVICE_REQUEST_ERROR,
  SR_TYPES,
} from 'Utils/constants/genericConstants';
import {parseToFloat, roundOff} from 'Utils/helpers/Numeric';
import {
  cardNumberFormatter,
  convertToTitleCase,
  createChargePaymentRequest,
  createPaymentAdditionalData,
  filterObj,
  isSavedPayment,
  managePaymentActivity,
  removeSavedPayment,
} from 'Utils/helpers/PaymentHelper';
import {getCurrency, updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {newPaymentSection1, buttonList} from './PaymentDetailViewDialog';
import {
  initialValues,
  FLAGGED_PAYMENT_VALUES,
  createValidationSchema,
} from './PaymentDetailViewDialogSchema';
import {v4 as uuidv4} from 'uuid';
import {getSolicitationId} from 'Utils/helpers/MemberDetails';
import {updateSR} from 'Redux/Slices/serviceRequest';
import {
  dateConversionToISOFormatIgnoreTimeZone,
  localFormattedDateToIso,
} from 'Utils/helpers/DateValidation';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import CardLookup from '../CardLookupDialog';
import Loading from 'Components/Loading';
import FlowTypeConfirmationPopup from './flowTypeConfirmationPopup';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';

const PaymentDetailViewDialog = (props) => {
  const {openPaymentDetailViewDialog, isOenPaymentDetailViewDialog, selectedPayment} = props;
  const [formValues, setFormValues]: any = useState(initialValues);
  const [error, setError] = useState('');
  const [paymentFormSection, setPaymentFormSection] = useState(newPaymentSection1);
  const [selectedPvId, setSelectedPvId] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState('');
  const [openCardLookup, isOpenCardLookup] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [submittedFormValues, setSubmittedFormValues] = useState({});
  const [buttonSection, setButtonSection] = useState(buttonList);

  const selectedSR = useSelector((state: any) => state.selectedSR?.getServiceRequestById);
  const memberDetails = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const loginDetails = useSelector((state: any) => state?.login);
  const currentMemberDetails = memberDetails?.currentMemberDetails;
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const transactionAmountMap = {
    [SR_TYPES.COMPLAINT.toUpperCase()]: selectedSR?.additionalData?.compensationAmount,
    [SR_TYPES.BOOK_CASHBACK_EVENT.toUpperCase()]: selectedSR?.additionalData?.totalActualPrice,
    [SR_TYPES.CINEMA_ECODE.toUpperCase()]: selectedSR?.additionalData?.totalActualPrice,
    [SR_TYPES.FILM_RENTAL_ECODE.toUpperCase()]: selectedSR?.additionalData?.totalActualPrice,
    [SR_TYPES.ADHOC_PAYMENT.toUpperCase()]: selectedSR?.additionalData?.transactionAmount,
    [SR_TYPES.ONSALE_REGISTRATION.toUpperCase()]: 0.0,
    [SR_TYPES.BOOK_EVENT.toUpperCase()]: selectedSR?.additionalData?.totalPriceAfterDiscount,
  };

  useEffect(() => {
    if (openPaymentDetailViewDialog) {
      let targetFields = {};
      targetFields['cardNumber'] = {
        targetAttribute: 'isDisabled',
        targetValue: false,
        targetAttribute1: 'required',
        targetValue1: true,
      };
      targetFields['flowType'] = {
        targetAttribute: 'options',
        targetValue: [
          {value: 'Debit', text: 'Debit'},
          {value: 'Credit', text: 'Credit'},
        ],
      };
      targetFields['amount'] = {
        targetAttribute: 'isDisabled',
        targetValue: false,
      };
      targetFields['account'] = {
        targetAttribute1: 'required',
        targetValue1: false,
      };
      targetFields['sortCode'] = {
        targetAttribute1: 'required',
        targetValue1: false,
      };
      targetFields['paymentType'] = {
        targetAttribute1: 'isDisabled',
        targetValue1: false,
      };

      let updatedFormContent = updateFormContent(newPaymentSection1, targetFields);
      setPaymentFormSection(updatedFormContent);
      let totalAmount = roundOff(
        parseToFloat(transactionAmountMap[selectedSR?.type.toUpperCase()]),
        2,
      );
      let flowType = 'Debit';

      if (
        selectedSR?.type === SR_TYPES.ADHOC_PAYMENT ||
        selectedSR?.type.toUpperCase() === SR_TYPES.COMPLAINT.toUpperCase()
      ) {
        flowType = 'Credit';
      }
      setCurrencyLogo();

      setFormValues((formValues) => ({
        ...formValues,
        amount: totalAmount,
        flowType: flowType,
      }));
      populateCardDetailsInForm();
    }
  }, [openPaymentDetailViewDialog]);

  useEffect(() => {
    if (selectedPayment) {
      populatePaymentVechileDetails();
      setSelectedPaymentIndex(selectedPayment.paymentIndex);
    }
  }, [selectedPayment]);

  const onClosePaymentDialog = (val) => {
    setFormValues(initialValues);
    isOenPaymentDetailViewDialog(val);
  };

  useEffect(() => {
    let paymentStatus = selectedPayment?.paymentStatus?.toUpperCase() || '';
    let targetButtonFields = {
      confirmPayment: {
        targetAttribute: 'isDisabled',
        targetValue: paymentStatus === PAYMENT_STATUS_CAPS.NEW || !paymentStatus ? false : true,
      },
    };

    let updateButtonList = updateFormContent(buttonSection, targetButtonFields);
    setButtonSection(updateButtonList);
  }, [selectedPayment]);

  useEffect(() => {
    if (selectedSR && selectedSR?.id) {
      setError('');
    } else {
      setError(SERVICE_REQUEST_ERROR);
    }
  }, [selectedSR]);

  const setCurrencyLogo = () => {
    let selectedCurrency = getCurrency(memberDetails);

    let targetFields = {
      icon: {
        targetAttribute: 'text',
        targetValue: CURRENCY_ICON[selectedCurrency],
      },
    };
    let updatedFormContent = updateFormContent(newPaymentSection1, targetFields);
    setPaymentFormSection(updatedFormContent);
  };

  const getPaymentVehicles = async (paymentVehiclesList) => {
    try {
      //toggleLoader(true);
      const headers = {
        'tenant-id': loginDetails?.tenantID,
        'user-access-token': loginDetails?.loginInfo?.access_token,
        'client-access-token': loginDetails?.loginInfo?.service_token,
      };
      const cardDetails = await gqlPaymentCardDetails(
        {
          idList: paymentVehiclesList,
        },
        headers,
      );
      let memberPaymentVehicleDetails;
      if (cardDetails?.error) {
        setError(getErrorMsg(cardDetails.error));
      } else if (cardDetails?.error) {
        setError(cardDetails?.message);
      } else {
        memberPaymentVehicleDetails =
          cardDetails &&
          cardDetails.data &&
          cardDetails.data.getPaymentVehiclesDetails &&
          cardDetails.data.getPaymentVehiclesDetails.paymentVehiclesDetails;
      }
      return memberPaymentVehicleDetails;
    } catch (e) {
      console.error(e);
    }
  };

  const populatePaymentVechileDetails = async () => {
    if (selectedPayment.paymentVehicleId) {
      let resp = await getPaymentVehicles([selectedPayment.paymentVehicleId]);
      let paymentDetails = resp?.length > 0 ? resp[0] : {};
      let paymentMethod = '';
      let auditDetails = {
        paymentCreatedDate: selectedPayment.createdOn,
        paymentModifiedDate: selectedPayment.modifiedOn,
      };

      if (paymentDetails.paymentMethod === 'CREDITCARD') {
        paymentMethod = PAYMENT_METHOD.CARD;
        setFormValues((formValues) => ({
          ...formValues,
          ...selectedPayment,
          ...auditDetails,
          cardNumber: cardNumberFormatter(paymentDetails?.paymentMethodDetails?.cardNumber),
          paymentType: convertToTitleCase(paymentDetails?.paymentMethodDetails?.paymentMethodType),
          paymentMethod: paymentMethod,
          amount: selectedPayment.paymentAmount,
        }));
      } else if (paymentDetails.paymentMethod === 'BANKACCOUNT') {
        paymentMethod = PAYMENT_METHOD.BANK;
        setFormValues((formValues) => ({
          ...formValues,
          ...selectedPayment,
          ...auditDetails,
          account: paymentDetails?.paymentMethodDetails?.accountNumber,
          sortCode:
            paymentDetails?.paymentMethodDetails?.bankDetail?.sortCode ||
            paymentDetails?.paymentMethodDetails?.bankDetail?.bankCode,
          bankCode: '',
          paymentMethod: paymentMethod,
          amount: selectedPayment.paymentAmount,
        }));
      } else {
        setFormValues((formValues) => ({
          ...formValues,
          ...selectedPayment,
          paymentMethod: paymentMethod,
          amount: selectedPayment.paymentAmount,
          ...auditDetails,
        }));
      }
      setSelectedPvId(selectedPayment.paymentVehicleId);
    }
  };

  const isPaymentSelected = () => {
    return selectedPayment && Object.keys(selectedPayment).length > 0;
  };

  const getSRAdditionalData = (srDetails, paymentStatus, paymentFlowType) => {
    if (
      (srDetails?.type?.toUpperCase() === SR_TYPES.BOOK_CASHBACK_EVENT.toUpperCase() ||
        srDetails?.type?.toUpperCase() === SR_TYPES.CINEMA_ECODE.toUpperCase() ||
        srDetails?.type?.toUpperCase() === SR_TYPES.FILM_RENTAL_ECODE.toUpperCase() ||
        srDetails?.type?.toUpperCase() === SR_TYPES.BOOK_EVENT.toUpperCase()) &&
      paymentStatus.toUpperCase() === PAYMENT_STATUS.ACCEPTED.toUpperCase() &&
      !srDetails?.additionalData?.bookingDate
    ) {
      return {
        ...srDetails.additionalData,
        bookingDate: dateConversionToISOFormatIgnoreTimeZone(new Date()),
      };
    }
    return srDetails.additionalData;
  };

  const updateSRWithPaymentDetails = async (
    srData,
    paymentActivityId,
    pvStatus,
    userInfo,
    paymentVehicleId,
    isPaymentSelected,
    memberDetails,
    oldPaymentIndex = '',
    paymentFlowType = '',
  ) => {
    let commands: any = [];
    let activityPayload = managePaymentActivity(
      srData,
      paymentActivityId,
      pvStatus,
      userInfo,
      paymentVehicleId,
      isPaymentSelected,
      oldPaymentIndex,
    );
    if (Array.isArray(activityPayload)) {
      commands = [...activityPayload];
    }

    commands.push({
      commandName: 'updateSR',
      commandId: 'updateSR2',
      type: srData.type,
      subType: srData.subtype,
      description: 'description',
      summary: srData.summary,
      status: srData.status,
      additionalData: getSRAdditionalData(srData, pvStatus, paymentFlowType),
      audit: {...srData.audit, modifiedBy: userInfo, modifiedOn: srData?.audit?.modifiedOn},
    });

    const currentMember = memberDetails?.currentMemberDetails;

    let updateSRVariable = {
      id: srData?.id,
      requestObj: {
        commands: commands,
      },
      notificationParams: {
        firstName: currentMember?.firstName || '',
        lastName: currentMember?.lastName || '',
        membershipNumber: memberDetails?.membershipNumber,
        toEmail: currentMember?.primaryEmail || currentMember?.clientEmail,
        packageId: memberDetails?.packageId || '',
        solicitationId: parseToFloat(getSolicitationId(memberDetails)),
        externalRefId: currentMember?.externalRefId || '',
        membershipEnabled: loginDetails?.tenantConfig?.membershipEnabled === 'true' ? true : false,
      },
    };
    if (!commands.length) return;

    try {
      let resp = await dispatch(updateSR(updateSRVariable)).unwrap();
      return resp;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  };

  const refreshSRData = async (srId) => {
    let payload = {
      id: srId,
    };
    try {
      let resp = await dispatch(searchSRByID(payload)).unwrap();

      return resp;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  };

  const recordingPaymentResponse = (billId, status, values) => {
    let selectedSRData = selectedSR;
    let paymentId = selectedPaymentIndex || billId || uuidv4();
    if (selectedPaymentIndex) {
      selectedSRData = removeSavedPayment(selectedPaymentIndex, selectedSR);
    }

    updateSRWithPaymentDetails(
      selectedSR,
      paymentId,
      status,
      ownerID,
      selectedPvId,
      isPaymentSelected(),
      memberDetails,
      selectedPaymentIndex,
      values.flowType,
    )
      .then(() => {
        refreshSRData(selectedSRData.id);
      })
      .then(() => {
        setLoading(false);
        isOenPaymentDetailViewDialog(false);
        setFormValues(initialValues);
      });
  };

  const confirmPayment = async (formValues) => {
    setLoading(true);
    if (selectedSR?.id) {
      try {
        setError('');

        let paymentPayload = createChargePaymentRequest(
          formValues,
          selectedPvId,
          selectedSR,
          currentMemberDetails,
          memberDetails,
          ownerID || '',
        );

        const headers = {
          'tenant-id': loginDetails?.tenantID,
          'user-access-token': loginDetails?.loginInfo?.access_token,
          'client-access-token': loginDetails?.loginInfo?.service_token,
        };
        let resp = await gqlChargePayment(paymentPayload, headers);
        let paymentResponse = resp.data?.payment;
        let billId = '';

        if (Array.isArray(paymentResponse?.errors) && paymentResponse?.errors.length) {
          billId = paymentResponse.billId || '';
        } else {
          billId = paymentResponse?.response?.billId;
        }

        if (!billId) {
          setError(paymentResponse.failureDescription);
        } else {
          recordingPaymentResponse(
            billId,
            PAYMENT_ERROR[paymentResponse?.response?.transactionStatus] ||
              PAYMENT_STATUS.TRANSACTION_ERROR,
            formValues,
          );
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setError('Something went wrong. Please try again!');
        console.log(error, 'error!!');
      }
    }
  };

  const createSavePaymentRequest = (
    formValues,
    selectedSRDetails,
    selectedPaymentIndex,
    selectedPvId,
    userInfo,
    paymentStatus,
    paymentResponse?,
  ) => {
    let updatedSrDetails = Object.assign({}, selectedSRDetails);
    let srPayment = [...(updatedSrDetails?.additionalData?.savedPayments || [])];
    let paymentIdentifier = selectedPaymentIndex || uuidv4();
    let sanitizedValues = filterObj(FLAGGED_PAYMENT_VALUES, formValues);
    let todayDate = new Date().toISOString();
    let isSavedPaymentUpdate = isSavedPayment(selectedSRDetails, selectedPaymentIndex);

    if (!selectedPaymentIndex || isSavedPaymentUpdate) {
      if (isSavedPaymentUpdate) {
        let index = srPayment.findIndex((payment) => payment.paymentIndex === selectedPaymentIndex);
        srPayment.splice(index, 1);
      }

      let newPayment = {
        ...sanitizedValues,
        paymentIndex: paymentIdentifier,
        paymentVehicleId: selectedPvId,
        billStatus: paymentStatus,
        createdOn: todayDate,
        modifiedOn: todayDate,
        statusDate: null,
        createdBy: userInfo,
        modifiedBy: userInfo,
        billId: paymentResponse?.billId || '',
      };
      srPayment.push(newPayment);
    }

    if (paymentStatus === PAYMENT_STATUS.CANCELLED) {
      srPayment.forEach((payment) => {
        if (payment.paymentIndex === selectedPaymentIndex) {
          payment.billStatus = paymentStatus;
        }
      });
    }
    updatedSrDetails.additionalData = {
      ...updatedSrDetails.additionalData,
      savedPayments: srPayment,
    };
    updatedSrDetails.audit = {
      ...updatedSrDetails.audit,
      modifiedBy: userInfo,
      modifiedOn: localFormattedDateToIso(new Date()),
    };

    return {srDetails: updatedSrDetails, paymentIdentifier: paymentIdentifier};
  };

  const handleSavePayment = (values, paymentStatus) => {
    let saveContract = createSavePaymentRequest(
      values,
      selectedSR,
      selectedPaymentIndex,
      selectedPvId,
      ownerID,
      paymentStatus,
    );
    let {srDetails, paymentIdentifier} = saveContract;

    let srWithAdditonalData = createPaymentAdditionalData(paymentIdentifier, srDetails, values);

    updateSRWithPaymentDetails(
      srWithAdditonalData,
      paymentIdentifier,
      paymentStatus,
      ownerID,
      selectedPvId,
      isPaymentSelected(),
      memberDetails,
      selectedPaymentIndex,
    )
      .then(() => {
        refreshSRData(srWithAdditonalData.id);
      })
      .then(() => {
        setLoading(false);
        isOenPaymentDetailViewDialog(false);
        setFormValues(initialValues);
      });
  };

  const updatePayment = (billId, memberDetails, comment, status, userName) => {
    let request = {
      input: {
        billId: billId,
        membershipNumber: memberDetails.membershipNumber,
        transactionStatus: status,
        comments: comment,
        user: userName,
      },
    };

    const headers = {
      'tenant-id': loginDetails?.tenantID,
      'user-access-token': loginDetails?.loginInfo?.access_token,
      'client-access-token': loginDetails?.loginInfo?.service_token,
    };

    try {
      return gqlUpdatePayment(request, headers);
    } catch (err) {
      console.log(err);
      return Promise.reject('Failed');
    }
  };

  const handlePaymentUpdate = (comments, transactionStatus) => {
    //toggleLoader(true);
    const resp = updatePayment(
      selectedPaymentIndex,
      memberDetails,
      comments,
      transactionStatus,
      ownerID,
    );
    resp
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.updatePayment &&
          res.data.updatePayment.status &&
          res.data.updatePayment.status.toUpperCase() === 'SUCCESS'
        ) {
          refreshSRData(selectedSR?.id).finally(() => {
            setLoading(false);
            isOenPaymentDetailViewDialog(false);
            setFormValues(initialValues);
          });
        } else {
          setError(PAYMENT_UPDATE_FAILED);
          setLoading(false);
        }
      })
      .catch((res) => {
        setError(PAYMENT_UPDATE_FAILED);
        setLoading(false);
      });
  };

  const savePayment = (values) => {
    setLoading(true);
    try {
      if (selectedSR?.id) {
        let paymentStatus = PAYMENT_STATUS.NEW;
        if (selectedPayment?.billStatus) {
          paymentStatus = selectedPayment?.billStatus;
        }

        if (paymentStatus.toUpperCase() === PAYMENT_STATUS.NEW.toUpperCase()) {
          handleSavePayment(values, paymentStatus);
        } else {
          //update payment comments only
          handlePaymentUpdate(values.comments, null);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      isOenPaymentDetailViewDialog(false);
      setFormValues(initialValues);
    }
  };

  const getDefaultCardDetails = async (pvIdList) => {
    const defaultCardDetails = await getPaymentVehicles(pvIdList);
    if (defaultCardDetails && defaultCardDetails.length) {
      let lastCard = defaultCardDetails[defaultCardDetails.length - 1];
      let {paymentMethodDetails} = lastCard;
      let defaultCardValue = {
        cardNumber: cardNumberFormatter(paymentMethodDetails?.cardNumber),
        cardType: paymentMethodDetails?.paymentMethodType,
        pvId: lastCard?.paymentVehicleId,
      };
      return defaultCardValue;
    }
    return {};
  };

  const setSelectedCard = (cardDetails) => {
    setFormValues((formValues) => ({
      ...formValues,
      amount:
        selectedPayment.paymentAmount ||
        formValues.amount ||
        roundOff(parseToFloat(transactionAmountMap[selectedSR?.type.toUpperCase()]), 2) ||
        '',
      bankCode: '',
      sortCode: '',
      paymentMethod: PAYMENT_METHOD.CARD,
      cardNumber: cardDetails.cardNumber,
      paymentType: cardDetails.cardType,
      expiryDate: cardDetails.expiryDate,
    }));
    setSelectedPvId(cardDetails.pvId);
  };

  const populateCardDetailsInForm = async () => {
    let pvIdList: any = [];
    let onSalePvId = selectedSR?.additionalData?.savedPayments?.[0]?.paymentVehicleId || '';
    if (onSalePvId) {
      pvIdList.push(onSalePvId);
    } else {
      (currentMemberDetails.paymentVehicles || []).forEach((item) => {
        if (item.isDefault && item.type === 'credit-card') {
          pvIdList.push(item.pvId);
        }
        return null;
      });
    }
    if (pvIdList.length) {
      let defaultCardValue = await getDefaultCardDetails(pvIdList);
      setSelectedCard(defaultCardValue);
    }
  };

  const populateBankAccountDetails = async (setField) => {
    if (currentMemberDetails) {
      let paymentVehicles = currentMemberDetails?.paymentVehicles;
      if (paymentVehicles.length) {
        let pvIdList: any = [];
        paymentVehicles.map((item) => {
          if (item.type === PAYMENT_METHOD_TYPE['Bank Account'] && item.isDefault) {
            pvIdList.push(item.pvId);
          }
          return null;
        });
        setSelectedPvId(pvIdList[0]);
        if (pvIdList.length) {
          const resp = await getPaymentVehicles(pvIdList);
          let paymentDetails = resp?.length > 0 ? resp[0] : {};
          setField('account', paymentDetails?.paymentMethodDetails?.accountNumber || '');
          setField('sortCode', paymentDetails?.paymentMethodDetails?.bankDetail.bankCode || '');
          setField('bankCode', '');
        }
      }
    }
  };

  const handleFieldValidationMethod = (methodName) => {
    let targetFields = {};
    if (methodName === PAYMENT_METHOD.BANK) {
      targetFields['cardNumber'] = {
        targetAttribute: 'isDisabled',
        targetValue: true,
        targetAttribute1: 'required',
        targetValue1: false,
      };
      targetFields['account'] = {
        targetAttribute1: 'required',
        targetValue1: true,
      };
      targetFields['sortCode'] = {
        targetAttribute1: 'required',
        targetValue1: true,
      };
      targetFields['paymentType'] = {
        targetAttribute1: 'isDisabled',
        targetValue1: true,
      };
      targetFields['flowType'] = {
        targetAttribute: 'options',
        targetValue: [{value: 'Credit', text: 'Credit'}],
      };
      targetFields['amount'] = {
        targetAttribute: 'isDisabled',
        targetValue:
          selectedSR.type === SR_TYPES.BOOK_CASHBACK_EVENT ||
          selectedSR.type === SR_TYPES.CINEMA_ECODE ||
          selectedSR.type === SR_TYPES.FILM_RENTAL_ECODE
            ? true
            : false,
      };
    } else if (methodName === PAYMENT_METHOD.CARD) {
      targetFields['cardNumber'] = {
        targetAttribute: 'isDisabled',
        targetValue: false,
        targetAttribute1: 'required',
        targetValue1: true,
      };
      targetFields['flowType'] = {
        targetAttribute: 'options',
        targetValue: [
          {value: 'Debit', text: 'Debit'},
          {value: 'Credit', text: 'Credit'},
        ],
      };
      targetFields['amount'] = {
        targetAttribute: 'isDisabled',
        targetValue: false,
      };
      targetFields['account'] = {
        targetAttribute1: 'required',
        targetValue1: false,
      };
      targetFields['sortCode'] = {
        targetAttribute1: 'required',
        targetValue1: false,
      };
      targetFields['paymentType'] = {
        targetAttribute1: 'isDisabled',
        targetValue1: false,
      };
    }
    let updatedFormContent = updateFormContent(newPaymentSection1, targetFields);
    setPaymentFormSection(updatedFormContent);
  };

  const onPaymentMethodChange = (e, setField) => {
    setSelectedPvId('');
    handleFieldValidationMethod(e.target.value);
    if (e.target.value === PAYMENT_METHOD.BANK) {
      setField('paymentType', '');
      setField('cardNumber', '');
      setField('flowType', 'Credit');
      setField(
        'amount',
        selectedSR.type === SR_TYPES.BOOK_CASHBACK_EVENT ||
          selectedSR.type === SR_TYPES.CINEMA_ECODE ||
          selectedSR.type === SR_TYPES.FILM_RENTAL_ECODE
          ? selectedSR?.additionalData?.totalCashback
          : '',
      );
      setPaymentMethod(PAYMENT_METHOD.BANK);
      populateBankAccountDetails(setField);
    } else if (e.target.value === PAYMENT_METHOD.CARD) {
      setSelectedPvId('');
      setField('paymentType', '');
      setField('account', '');
      setField('sortCode', '');
      setField('bankCode', '');
      setField('amount', selectedSR?.additionalData?.totalActualPrice);
      setPaymentMethod(PAYMENT_METHOD.CARD);
      populateCardDetailsInForm();
    }
  };

  const onFlowTypeChange = (e, setField) => {
    let value = e.target.value;

    if (value === 'Debit' && paymentMethod === PAYMENT_METHOD.BANK) {
      setSelectedPvId('');
      setField('account', '');
      setField('sortCode', '');
      setField('bankCode', '');
    } else {
      setFormValues((formValues) => ({
        ...formValues,
        flowType: value,
      }));
    }
    // if (paymentMethod === PAYMENT_METHOD.BANK && e.target.value === 'Credit') {
    //   setValidatedFields({...validatedFields, flowTypeValid: true});
    // } else if (paymentMethod === PAYMENT_METHOD.BANK && e.target.value === 'Debit') {
    //   setValidatedFields({...validatedFields, flowTypeValid: false});
    // } else {
    //   setValidatedFields({...validatedFields, flowTypeValid: true});
    // }
  };

  const customEvents = {
    paymentMethod: {
      onChange: onPaymentMethodChange,
    },
    flowType: {
      onChange: onFlowTypeChange,
    },
    cardNumber: {
      onClick: (e, f, values) => {
        setFormValues(values);
        isOpenCardLookup(true);
      },
    },
  };
  const handleOnSubmit = (values, action) => {
    if (values?.buttonAction) {
      switch (values?.buttonAction) {
        case 'Save':
          savePayment(values);
          break;
        case 'Confirm Payment':
          if (values.flowType === 'Credit') {
            setOpenConfirmationDialog(true);
            setSubmittedFormValues(values);
          } else {
            confirmPayment(values);
          }
          break;
        case 'Reset':
          action.resetForm();
          break;
      }
    }
  };

  const handleConfirmation = (buttonAction, isCloseDialog) => {
    setOpenConfirmationDialog(isCloseDialog);
    if (buttonAction) {
      confirmPayment(submittedFormValues);
    }
  };

  return (
    <DialogView
      closeDialogMethod={onClosePaymentDialog}
      open={openPaymentDetailViewDialog}
      id="payment-dialog">
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Form
          onSubmit={handleOnSubmit}
          validationSchema={createValidationSchema(paymentMethod)}
          initialValues={formValues}
          section1={paymentFormSection}
          buttonStack={buttonSection}
          customEvents={customEvents}
        />
        {error === '' ? null : (
          <SnackBar
            isSnackBarOpen={isSnackBarOpen}
            closeSnackBar={closeSnackBar}
            errorMessage={error}
          />
        )}
        {openCardLookup ? (
          <CardLookup
            openCardLookup={openCardLookup}
            isOpenCardLookup={isOpenCardLookup}
            // handleLookupClose={toggleCardLookup}
            setSelectedCard={(cardData) => {
              setSelectedCard(cardData);
              isOpenCardLookup(false);
            }}
          />
        ) : null}
      </DialogContent>
      <FlowTypeConfirmationPopup
        openConfirmationDialog={openConfirmationDialog}
        handleConfirmation={handleConfirmation}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
      />
      {loading && <Loading />}
    </DialogView>
  );
};

export default PaymentDetailViewDialog;
