import {LoadingButton} from '@mui/lab';
import {Box, Divider, IconButton, Paper, Stack, Typography} from '@mui/material';
import {CustomDatePicker, CustomTextInput, NoSearchResults, SnackBar, Wrapper} from 'Components';
import {useCSContext} from 'Context/ContentStackContext';
import {useFormik} from 'formik';
import {useSnackBar} from 'Hooks';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getAllMetadata} from 'Redux/Slices/allMetadata';
import {setTenantDetails, updateTenantID} from 'Redux/Slices/login';
import {fetchMemberDetails, setCurrentMemberBenefitDetails} from 'Redux/Slices/selectedMember';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {getTenantServiceMapping} from 'Redux/Slices/TenantServicesMappings';
import iconSize from 'Theme/Icon';
import globalSpacing from 'Theme/Spacing';
import {
  agentRole,
  SEARCH_NO_RESULTS_ERROR,
  V2_ENABLED_CLIENTS,
} from 'Utils/constants/genericConstants';
import {DASHBOARD} from 'Utils/constants/Paths';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {resetReduxOnSearch} from 'Utils/helpers/searchHelper';
import {trimFormValues} from 'Utils/helpers/SRDetailViewHelper';
import * as Yup from 'yup';
import {fetchMember} from '../../Redux/Slices/member';
import MultipleResults from './components/MultipleResult';
import {MEMBER_SEARCH} from './searchConstants';
import styles from './styles';

const SearchMember = () => {
  const dispatch = useDispatch();
  const clientArray = useSelector((state: any) => state?.login?.loginInfo?.configs);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const loginDetails = useSelector((state: any) => state?.login);
  const client = clientArray.map((item) => item['name']);
  const {artifacts} = useCSContext();
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [multipleResults, setMultipleResults] = useState<any>();
  const navigate = useNavigate();
  const status = ['Active', 'Cancelled', 'Incomplete', 'Suspended', 'Unknown'];
  const role = useSelector((state: any) => state?.login?.loginInfo?.role);
  const searchResultRef = useRef<any>();

  const validationSchema = Yup.object({
    client: Yup.string().required(),
  });

  const formik: any = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    initialValues: {
      client: client?.[0],
    },
    onSubmit: async (values: any) => {
      let formValues = trimFormValues(values);
      searchMember(formValues);
    },
  });
  const clearMessageOnClose = () => {
    setError('');
  };
  const _handleResponse = async (data, loading, errorObj) => {
    if (errorObj?.error) {
      setError(getErrorMsg(errorObj.error));
    } else if (data?.length === 1) {
      const variable = {
        memberId: data[0].memberId,
        membershipId: data[0].membershipId ?? data[0].memberId,
      };
      if (data?.[0]?.tenantId) {
        await dispatch(updateTenantID(data?.[0]?.tenantId));
      }
      const response = await dispatch(fetchMemberDetails({variable})).unwrap();
      if (response?.error) {
        setError(getErrorMsg(response.error));
      } else if (response) {
        dispatch(setCurrentMemberBenefitDetails(tenantConfig?.additionalBenefits || '[]'));
        resetReduxOnSearch(dispatch);
        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
            filterType: 'Recent SRs',
          }),
        );
        dispatch(getTenantServiceMapping({packageId: response.packageId}));
        dispatch(getAllMetadata({}));
        //setMember(data[0]);
        navigate(DASHBOARD, {replace: true});
      } else {
        setError(SEARCH_NO_RESULTS_ERROR);
      }
    } else if (data?.length > 1) {
      setMultipleResults(data);
      executeSearchResultScroll();
    }
    setLoading(loading);
  };

  const searchMember = async (values) => {
    setLoading(true);
    multipleResults && setMultipleResults(null);
    noResults && setNoResults(false);
    const payload = getPayload(values);
    try {
      const response = await dispatch(fetchMember({payload})).unwrap();
      _handleResponse(response?.data?.searchMember?.memberDetails, loading, response);
    } catch (error) {
      console.warn('Error', error);
    }
  };

  const getPayload = (values) => {
    const selectedTenant = clientArray.filter((client) => client.name === values.client);

    const payload = {
      client: values.address, //selectedTenant?.[0]?.['tenant-id'],
      addressLine1: values.address || '',
      email: values.email || '',
      externalRef: values.externalId || '',
      postalCode: values.postCode || '',
      lastName: values.lastName || '',
      phone: values.phoneNumber || '',
      membershipNumber: values.membershipNumber || '',
      status: values.status || '',
      city: values.city || '',
      firstName: values.firstName || '',
      membershipEnabled:
        selectedTenant?.[0]?.tenantConfigs?.membershipEnabled === 'true' ? true : false,
    };

    return payload;
  };

  useEffect(() => {
    dispatch(setTenantDetails(client[0]));
  }, []);

  const executeSearchResultScroll = () => {
    if (!searchResultRef.current) return;
    //window.scrollBy(0, 100);
    searchResultRef.current.scrollIntoView({behavior: 'smooth'});
  };
  return (
    <Wrapper>
      <Paper sx={role === agentRole.level1 ? styles.paper : styles.adminPaper}>
        {role === agentRole.level1 ? (
          <>
            <Box p={globalSpacing.md} sx={styles.topContainer}>
              <img src={artifacts.ast_icon} alt="ast icon" />
              <Typography variant="h1" px={globalSpacing.xs}>
                {MEMBER_SEARCH.heading}
              </Typography>
            </Box>
            <Divider light />
          </>
        ) : null}

        <Stack
          spacing={globalSpacing.s}
          sx={{padding: role === agentRole.level1 ? globalSpacing.lg : globalSpacing.md}}>
          <Typography variant="h3"> {MEMBER_SEARCH.subtitle} </Typography>
          {role === agentRole.level1 ? (
            <Typography variant="h6">{MEMBER_SEARCH.member_search_text}</Typography>
          ) : null}
          {!(role === agentRole.level1) ? <Divider light /> : null}

          <Box sx={styles.rowContainer}>
            <CustomTextInput
              label="Client"
              name="client"
              inline
              queryTypesMenu={client}
              onChange={(e) => {
                dispatch(setTenantDetails(e.target.value));
                formik.handleChange(e);
              }}
              value={formik.values['client']}
            />
            <CustomTextInput
              label={role === agentRole.level1 ? 'Member Identifier' : 'External Ref#'}
              inline
              name="externalId"
              onChange={formik.handleChange}
              value={formik.values['externalId']}
            />

            {!(role === agentRole.level1) ? (
              <CustomTextInput
                label="Membership Number"
                inline
                name="membershipNumber"
                onChange={formik.handleChange}
                value={formik.values['membershipNumber']}
              />
            ) : null}
          </Box>
          {!(role === agentRole.level1) ? (
            <>
              <Box sx={styles.rowContainer}>
                <CustomTextInput
                  label="Last Name"
                  name="lastName"
                  inline
                  onChange={formik.handleChange}
                  value={formik.values['lastName']}
                />
                <CustomTextInput
                  label="First Name"
                  inline
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values['firstName']}
                />

                <CustomTextInput
                  label="Email"
                  inline
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values['email']}
                />
              </Box>

              <Box sx={styles.rowContainer}>
                <CustomTextInput
                  label="Post Code"
                  name="postCode"
                  inline
                  disabled={V2_ENABLED_CLIENTS.includes(loginDetails?.tenantID)}
                  onChange={formik.handleChange}
                  value={formik.values['postCode']}
                />
                <CustomTextInput
                  label="City "
                  inline
                  disabled={V2_ENABLED_CLIENTS.includes(loginDetails?.tenantID)}
                  name="city"
                  onChange={formik.handleChange}
                  value={formik.values['city']}
                />
                <CustomTextInput
                  label="Address"
                  name="address"
                  disabled={V2_ENABLED_CLIENTS.includes(loginDetails?.tenantID)}
                  inline
                  onChange={formik.handleChange}
                  value={formik.values['address']}
                />
              </Box>
              <Box sx={styles.rowContainer}>
                <CustomDatePicker
                  label="Date of Birth"
                  date={formik.values['birthDate'] || null}
                  inline
                  position="start"
                  disabled={V2_ENABLED_CLIENTS.includes(loginDetails?.tenantID)}
                  handleChange={(searchDate) => formik.setFieldValue('birthDate', searchDate)}
                  reset={() => formik.setFieldValue(['birthDate'], null)}
                />
                <CustomTextInput
                  label="Phone Number "
                  name="phoneNumber"
                  inline
                  disabled={V2_ENABLED_CLIENTS.includes(loginDetails?.tenantID)}
                  onChange={formik.handleChange}
                  value={formik.values['phoneNumber']}
                />

                <CustomTextInput
                  label="Status"
                  name="status"
                  inline
                  disabled={V2_ENABLED_CLIENTS.includes(loginDetails?.tenantID)}
                  queryStatusMenu={status}
                  onChange={formik.handleChange}
                  value={formik.values['status']}
                />
              </Box>
            </>
          ) : (
            ''
          )}
          <Box sx={styles.rowContainer}>
            <LoadingButton
              disabled={formik.values['client'] ? false : true}
              onClick={(e) => formik.handleSubmit(e)}
              variant="contained"
              sx={{width: '25%'}}
              loading={loading}>
              {MEMBER_SEARCH.button_text}
            </LoadingButton>
            <IconButton
              color="primary"
              aria-label="Reset Form"
              onClick={(e) => {
                formik.resetForm({});
                multipleResults && setMultipleResults(null);
                noResults && setNoResults(false);
              }}
              sx={{paddingY: '0rem', marginLeft: globalSpacing.xs}}>
              <img src={artifacts.refresh} style={iconSize} alt="Reset Form" />
            </IconButton>
          </Box>
        </Stack>
      </Paper>
      <div ref={searchResultRef}>
        {noResults && <NoSearchResults CSTexts={MEMBER_SEARCH.no_search_result} />}
        {multipleResults && <MultipleResults results={multipleResults} />}
      </div>
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
          clearMessageOnClose={clearMessageOnClose}
        />
      )}
    </Wrapper>
  );
};

export default SearchMember;
