import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchEvent} from 'Redux/Slices/eventList';
import {CustomTextInput, JsonToCsv, NoResults, TabHeading} from 'Components';
import {getEventssGirdData} from './getEventssGirdData';
import {SortOrder} from 'Utils/enums';
import TableView from 'Components/TableView';
import {Loading, SnackBar} from 'Components';
import {useSnackBar} from 'Hooks';
import {EVENTS} from './eventConstants';
import globalSpacing from 'Theme/Spacing';
import {Box} from '@mui/material';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';

interface IProps {}

const Event: React.FC<IProps> = (props) => {
  const [tableData, setTableData] = useState<any[]>([]);

  const dispatch = useDispatch();
  const event = useSelector((state: any) => state?.event?.data?.clickEventDataList);
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  // const hasFetched = useRef(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);

  const filter: string[] = [
    'Select All',
    'Address Change',
    'Subscription',
    'Tier Change',
    // 'Email Change',
    // 'Phone Change',
    'Forgot Password',
    'Reset Password',
    'Forgot Username',
    'Reset Webenable',
  ];

  const filterMapper: {[key: string]: string} = {
    'Select All': '',
    'Address Change': 'address-change',
    Subscription: 'subscription-change',
    'Tier Change': 'tier-change',
    // 'Email Change': 'email-change',
    // 'Phone Change': 'phone-change',
    'Forgot Password': 'forgot-password',
    'Reset Password': 'reset-password',
    'Forgot Username': 'forgot-username',
    'Reset Webenable': 'reset-webenable',
  };
  const [filterByEvent, setFilterByEvent] = useState(filter[0]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let res = await dispatch(fetchEvent(filterMapper[filterByEvent]));
      if (res?.error) {
        setError(res?.error?.message);
      }
      setLoading(false);
    })();
  }, [filterByEvent, selectedMember?.currentMemberDetails]);

  useEffect(() => {
    setFilterByEvent(filter[0]);
  }, [selectedMember?.currentMemberDetails]);

  useEffect(() => {
    const tempTableData: any = [];
    (event || []).forEach((event) => {
      tempTableData.push({
        label: event.eventName,
        activityType: event.additonalAttributes?.activityType,
        activtiyDate: dateConversionIsoToLocal(event.date, FORMAT_TYPE.DATE_TIME, ''),
        code: event.source,
        value: event.eventId,
      });
    });
    setTableData(tempTableData);
  }, [event]);

  const onRowClick = () => {};

  if (loading) return <Loading isOpen={true} />;

  return (
    <>
      <TabHeading data={EVENTS} />
      <Box
        sx={{
          marginTop: globalSpacing.sm,
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <CustomTextInput
          // disabled={filterDisabled}
          inline
          label={EVENTS.filter_label}
          name="filter"
          filterMenu={filter}
          value={filterByEvent}
          onChange={(e) => {
            setFilterByEvent(e.target.value);
          }}
        />
      </Box>
      {event?.length ? (
        <>
          <JsonToCsv jsonData={event} />
          <TableView
            {...getEventssGirdData(tableData, SortOrder.DESCENDING, '', false)}
            onRowClick={onRowClick}
          />
        </>
      ) : (
        <NoResults text={EVENTS.no_results_text} />
      )}

      {error && (
        <SnackBar
          errorMessage={error}
          closeSnackBar={closeSnackBar}
          isSnackBarOpen={isSnackBarOpen}
        />
      )}
    </>
  );
};

export default Event;
