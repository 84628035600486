import {PAYMENT_STATUS} from 'Utils/constants/genericConstants';
import {getSolicitationId} from './MemberDetails';
import {parseToFloat, roundOff} from './Numeric';

export const getTicketItemDetails = (formValues, ticketItemDetails: any[], noCashback: any) => {
  let maxLineId = '1';
  if (ticketItemDetails && ticketItemDetails.length) {
    for (let i = 0; i < ticketItemDetails.length; i++) {
      if (maxLineId.localeCompare(ticketItemDetails[i]?.additionalInfo?.ticketLineId) < 0) {
        maxLineId = ticketItemDetails[i]?.additionalInfo?.ticketLineId;
      }
    }
  }
  if (ticketItemDetails && ticketItemDetails.length) {
    maxLineId = (parseToFloat(maxLineId) + 1).toString();
  }
  return {
    additionalInfo: {
      notes: '',
      ticketLineId: maxLineId,
    },
    pricePerUnit: roundOff(parseToFloat(formValues.pricePerUnit), 2),
    quantity: parseToFloat(formValues.quantity),
    totalPrice: parseToFloat(
      calculateTotalPriceLine(formValues.quantity, formValues.bookingFee, formValues.pricePerUnit),
    ),
    type: formValues.type,
    status: 'New',
    isAvailCashback: !noCashback,
    bookingFee: roundOff(parseToFloat(formValues.bookingFee), 2),
  };
};

const calculateTotalPriceLine = (numberOfTickets, bookingFee, perTicketPrice) => {
  const ticketsCount = parseToFloat(numberOfTickets);
  const fee = parseToFloat(bookingFee);
  const pricePerTicket = parseToFloat(perTicketPrice);

  return roundOff((ticketsCount * (fee * 100 + pricePerTicket * 100)) / 100, 2);
};

export const isUlsterRIMember = (memberDetails) => {
  if (!memberDetails) {
    return false;
  }
  return (
    [1043726, 1043756, 1043876, 1043916, 1043606, 1044126].indexOf(memberDetails.packageId) !== -1
  );
};

export const add = (obj: any[], value: string) => {
  var total = 0.0;
  obj.map((val) => (total += parseToFloat(val[value])));
  return total;
};

export const getCurrency = (memberDetails) => {
  let currency = 'GBP';
  if (isUlsterRIMember(memberDetails)) {
    currency = 'EUR';
  }
  return currency;
};

export const getBookingFee = (obj: any[], value: string, totalNumberOfTicketsField) => {
  var total = 0.0;
  obj.map(
    (val) => (total += parseToFloat(val[value]) * parseFloat(val[totalNumberOfTicketsField])),
  );
  return total;
};

export const getTotalActualPrice = async (units, postageFee, conversionRate) => {
  var totalActualPrice =
    add(units, 'totalPrice') * parseToFloat(conversionRate) + parseToFloat(postageFee);
  totalActualPrice = roundOff(totalActualPrice, 2);
  return totalActualPrice;
};

export const getNotificationParams = (selectedMemberDetails, email, membershipEnabled) => {
  const selectedMember = selectedMemberDetails.currentMemberDetails;
  return {
    firstName: selectedMember?.firstName,
    lastName: selectedMember?.lastName,
    membershipNumber: selectedMemberDetails?.membershipNumber,
    toEmail: email || selectedMember?.primaryEmail || selectedMember?.clientEmail,
    packageId: selectedMemberDetails?.packageId,
    solicitationId: parseToFloat(getSolicitationId(selectedMemberDetails)),
    externalRefId: selectedMember?.externalRefId,
    membershipEnabled: membershipEnabled,
  };
};

export const getDeliveryAddress = (formValues, member) => {
  return formValues.isUseMailingAddress
    ? getAddressFromMember(formValues, member)
    : getAddressFromFormValues(formValues);
};

const getAddressFromMember = (formValues, member) => ({
  addressLine1: member.addressLine1,
  addressLine2: member.addressLine2,
  addressLine3: '',
  city: member.city,
  postalCode: member.postalCode,
  county: member.county,
  countryName: member.country,
  state: member.state,
  phoneNumber: formValues.phoneNumber,
  mobileNumber: formValues.mobileNumber,
  alternatePhoneNumber: formValues.alternatePhoneNumber,
});

const getAddressFromFormValues = (formValues) => ({
  addressLine1: formValues.addressLineOne,
  addressLine2: formValues.addressLineTwo,
  addressLine3: formValues.addressLineThree,
  city: formValues.city,
  county: formValues.county,
  postalCode: formValues.postalCode,
  countryName: formValues.country,
  countryISOCode: '',
  phoneNumber: formValues.phoneNumber,
  mobileNumber: formValues.mobileNumber,
  alternatePhoneNumber: formValues.alternatePhoneNumber,
  state: formValues.state,
});

export const hideShowFormFields = (formFields, hiddenFields, isUseMailingAddress) => {
  hiddenFields.map((field) => {
    let objIndex = formFields.fields.findIndex((obj) => obj.name == field);
    formFields.fields[objIndex].display = isUseMailingAddress ? 'none' : 'block';
  });
  return formFields;
};

// export const getCountries = async () => {
//   // if (countries) {
//   //   return;
//   // }
//   const res = await callApi(GET_COUNTRIES, {});
//   return res.getCountries
//   // new CountriesModel({
//   //   id: "0",
//   //   countries: res.getCountries,
//   // }).$save();
// }

// export const getCashbackRequest = (
//   memberDetails,
//   units,
//   currency,
//   extraCashbackPercentage,
//   totalBookingFee,
//   postageFee,
//   category,
// ) => {
//   let requestUnits = units.map((unit) => {
//     return {
//       quantity: unit.quantity,
//       type: unit.type,
//       pricePerUnit: unit.pricePerUnit,
//       totalPrice: unit.totalPrice,
//       bookingFee: unit.bookingFee,
//       isAvailCashback: !unit.isAvailCashback,
//       status: unit.status,
//     };
//   });
//   return {
//     request: {
//       membershipId: memberDetails?.membershipId,
//       memberId: memberDetails?.currentMember?.memberId,
//       channel: 'Engage',
//       sourceApplication: 'ng-engage',
//       item: {
//         category,
//         currency,
//         totalBookingFee,
//         postageFee,
//         name: '',
//         type: '',
//         units: [ ...requestUnits ],
//         extraCashbackPercentage,
//       },
//     },
//   };
// };

export const isPaymentSuccessful = (selectedSR) => {
  const {payments} = selectedSR.additionalData;
  if (payments && payments.length > 0) {
    const successfulPayments = payments.filter(
      (payment) =>
        payment.billStatus && payment.billStatus.toUpperCase() === PAYMENT_STATUS.ACCEPTED,
    );
    return successfulPayments && successfulPayments.length > 0;
  }
  return false;
};

export const getSeatDetails = (seats) => {
  if (!seats || seats.length === 0) {
    return '';
  }
  let seatsInfo = seats.map((seatDetails) => {
    if (seatDetails.block || seatDetails.row || seatDetails.seat) {
      return (
        (seatDetails.block ? seatDetails.block + ' ' : '') +
        (seatDetails.row ? seatDetails.row + '-' : '') +
        (seatDetails.seat || '')
      );
    }
    return seatDetails.additionalDetails;
  });
  return seatsInfo.join(',');
};

export const isMemberEligibleForCashback = (memberCashbackEligibility) => {
  if (!memberCashbackEligibility) {
    return true;
  }
  return memberCashbackEligibility.eligible;
};

export const isLastCashbackBooking = (memberCashbackEligibility) => {
  if (!memberCashbackEligibility) {
    return false;
  }
  return memberCashbackEligibility.remainingBooking === 1;
};
