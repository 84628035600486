export const getBrandingData = `query MyQuery($tenant_identity:Int!,$locale: String!) {
   all_ast_global(locale: $locale, where: {tenant: {tenant: {tenant_identity: $tenant_identity}}}) {
    items {
      navigation_tab_barConnection {
        edges {
          node {
            ... on AstNavigationTabBar {
              tab {
                tab_title
              }
              iframe_tab {
                tab_title
              }
            }
          }
        }
      }
      branding_reloadedConnection {
        edges {
          node {
            ... on BrandingReloaded {
              title
              spacingvariable
              palette {
                background {
                  default
                  paper
                }
                common {
                  black
                  white
                }
                error {
                  contrastText: contrast_text
                  dark
                  light
                  main
                }
                primary {
                  contrastText: contrast_text
                  dark
                  light
                  main
                }
                secondary {
                  contrastText: contrast_text
                  dark
                  main
                  light
                }
                success {
                  contrastText: contrast_text
                  dark
                  light
                  main
                }
                text {
                  disabled
                  hint
                  primary
                  secondary
                }
                warning {
                  contrastText: contrast_text
                  dark
                  light
                  main
                }
              }
              fonts {
                boldConnection {
                  edges {
                    node {
                      url
                      title
                    }
                  }
                }
                regularConnection {
                  edges {
                    node {
                      url
                      title
                    }
                  }
                }
                semiboldConnection {
                  edges {
                    node {
                      url
                      title
                    }
                  }
                }
                headline_regularConnection {
                  edges {
                    node {
                      url
                      title
                    }
                  }
                }
                headline_boldConnection {
                  edges {
                    node {
                      url
                      title
                    }
                  }
                }
              }
              configurations {
                date_notationConnection {
                  edges {
                    node {
                      ... on AstDateNotaion {
                        title
                        format
                      }
                    }
                  }
                }
                theme_referenceConnection {
                  edges {
                    node {
                      ... on DefaultTheme {
                        title
                        theme
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      headerConnection {
        edges {
          node {
            ... on AstHeader {
              heading
              search_button_title 
              logout_button_title
              query_dashboard_title
            }
          }
        }
      }
    }
  }
}
`;

export const searchMember = `
  query searchMember(
    $dateOfBirth: Instant
    $client: String
    $addressLine1: String
    $email: String
    $externalRef: String
    $postalCode: String
    $lastName: String
    $phone: String
    $membershipNumber: String
    $status: String
    $city: String
    $firstName: String
    $pageSize: String
    $pageNumber: String
    $sortOrder: String
    $sortBy: String
    $membershipEnabled: Boolean!
  ) {
    searchMember(
      input: {
        dateOfBirth: $dateOfBirth
        client: $client
        addressLine1: $addressLine1
        email: $email
        externalRef: $externalRef
        postalCode: $postalCode
        lastName: $lastName
        phone: $phone
        membershipNumber: $membershipNumber
        status: $status
        city: $city
        firstName: $firstName
        membershipEnabled: $membershipEnabled
      }
      paging: {
        pageSize: $pageSize
        pageNumber: $pageNumber
        sortBy: $sortOrder
        sortOrder: $sortBy
      }
    ) {
      memberDetails {
        addressLine1
        city
        dateOfBirth
        firstName
        lastName
        memberId
        memberNumber
        membershipNumber
        membershipId
        membershipId
        packageId
        packageName
        postalCode
        status
        tenantId
        externalRefId
      }
      paging {
        pageSize
        sortOrder
        sortBy
        totalPages
        pageNumber
      }
    }
  }
`;

export const getMemberDetails = `query memberDetails($memberId: UUID, $membershipId: String!) {
  memberDetails(input: { memberId: $memberId, membershipId: $membershipId }) {
    focusedMemberDetails
    jointMemberDetails
    active
    packageId
    packageRefId
    packageName
    editProfile
    groupType
    memberType
    membershipId
    membershipNumber
    status {
      startDate
      endDate
      reason
      code
    }
    subscriptions {
      startDate
      solicitationId
      endDate
      attributes
      code
      reason
      packageId
      packageName
      tierTo
      packageRefId
    }
    enrollmentDate
    trialEndDate
    nextBillingDate
    renewalDate
    isDownSellAvailable
    ownedBy
    partner
    overseasAddress
    lineOfBusiness
    benefits {
      id
      category
      description
      name
      type
      vendorId
      vendorName
      redirectionDto {
        url
        httpMethod
        requestParameters
        profileParameters
        defaultParameters
        overriddenCommands
        keyValuePairs
        encryption {
          seedValue
          key
          algorithm
        }
        hashingAlgorithm
      }
    }
    sortCode
    accountNumber
    additionalData
  }
}
`;

export const getMemberByExtID = `query searchMemberByExtRefId($searchMemberByExtRefId: MemberRequestByExtRefInput!) {
  searchMemberByExtRefId(searchMemberByExtRefId: $searchMemberByExtRefId) {
    id 
    externalRefId
    firstName
    lastName
    middleName
    suffix
    salutation
    gender
    dateOfBirth 
    addresses {
      type
      postalCode
      country
      addressLine1
      addressLine2
      addressLine3
      streetName
      houseNo
      houseName
      flatNo
      city
      cityCode
      state
      stateCode
      countryCode
      country
      county
      status{ 
        isActive
        startDate
        }
      } 
      status 
      preferences{ 
        preferenceType
        hasOpted
        value
        status{
          isActive
          startDate
        }
      }
      communication{
        communicationType
        communicationSubType
        communicationValue
        isPrimary
        status{ 
          isActive
          startDate
        }
      }
      activeFrom
      activeTo
      isActive
      isWebEnabled
      webEnableDate
      memberJoinDate
      addresseeLine1
      addresseeLine2
      addresseeLine3
      brandName
      languageCode
      attributes
    }
  }

`;

export const getTransactions = `query getTransactionByRefId($referenceId: String!,$ngMemberId: String!,$pageSize: Int!, $pageNo: Int!){
  getTransactionByRefId(referenceId: $referenceId,
  ngMemberId: $ngMemberId,
  pageSize: $pageSize,
  pageNo: $pageNo){
      transactionDetails{
          transactionType
          ngMemberId
          claimDate
          expiryDate
          transactionStatus
          offerTitle
          offerId
          offerType
          merchantName
      }
      pagingInfo{
        pageNumber
        pageSize
        totalResults
      }
  }
}
`;

export const getTransactionsByClaimDate = `query getTransactionsByClaimDate($referenceId: String!,$claimDate: String!,$ngMemberId: String!, $pageSize: Int!, $pageNo: Int!){
  getTransactionsByClaimDate(referenceId: $referenceId,
  claimDate: $claimDate,
  ngMemberId: $ngMemberId,
  pageSize: $pageSize,
  pageNo: $pageNo){
      transactionDetails{
          transactionType
          ngMemberId
          claimDate
          expiryDate
          channel
          transactionStatus
          offerTitle
          offerId
          offerType
          merchantName
      }
      pagingInfo{
        pageNumber
        pageSize
        totalResults
      }
      }
  }
`;

export const getTransactionsByType = `query getTransactionByType($referenceId: String!,$offerType: String!, $pageSize: Int!, $pageNo: Int!){
  getTransactionByType(referenceId: $referenceId,
      offerType: $offerType
      pageSize: $pageSize,
      pageNo: $pageNo){
          transactionDetails{
              transactionType
              ngMemberId
              claimDate
              expiryDate
              channel
              transactionStatus
              offerTitle
              offerId
              offerType
              merchantName
}
pagingInfo{
  pageNumber
  pageSize
  totalResults
}
}

}
`;

export const getTransactionsByClaimDateAndType = `query getTransactionsByClaimDateAndType($referenceId: String!,$claimDate: String!, $offerType: String!,$ngMemberId: String!, $pageSize: Int!, $pageNo: Int!){
  getTransactionsByClaimDateAndType(referenceId: $referenceId,
  claimDate: $claimDate,
  offerType: $offerType,
  ngMemberId: $ngMemberId,
  pageSize: $pageSize,
  pageNo: $pageNo){
      transactionDetails{
          transactionType
          ngMemberId
          claimDate
          expiryDate
          channel
          transactionStatus
          offerTitle
          offerId
          offerType
          merchantName
      }
      pagingInfo{
        pageNumber
        pageSize
        totalResults
      }
  }
   
}
`;

export const getTransactionDetails = `query getTransactionDetailsByReferenceId($referenceId: String!,$offerId: String!,$ngMemberId: String){
  getTransactionDetailsByReferenceId(referenceId: $referenceId, offerId:$offerId, ngMemberId: $ngMemberId){
     title
     titleShort
     description
     termsAndConditions
     termsAndConditionsUrl
     claimDate
     expireDate
     offerStartDate
     offerEndDate
     offerType
     channel
     merchantName
     transactionStatus
     userClaimCount
     claimLimit
 }
}`;

export const getGiftingInfo = `query offersByCategory($name: String!, $pageNo: Int!, $pageSize: Int!) {offersByCategory(name: $name, pageNo: $pageNo, pageSize: $pageSize) { name offers { id offerType weight voucherCodeExpiryModelType  startDateTime  endDateTime  carouselCardTextColor  showPreClaimPopup  preClaimAdvice  title  titleShort  teaser  headline  description  keyTerms  termsAndConditions  termsAndConditionsUrl  claimLimitsReached {  user  total  userPerPeriod  totalPerPeriod  notEnoughCodes  }  redemptionDetails {  method  type  url  guidelines  }  images { classifier  imageUrl  altText }  merchant {  id  name  imageUrl  altText  }  claimConditionsFailed {  dimension  subDimension  values  }  ctaDetails {  key  value  }  additionalDetails {  key  value }  comingSoonDetails {  title  shortTitle  images {  classifier  imageUrl  altText  }  }  }  pagingInfo {  pageSize  pageNumber  totalResults  }  } }`;

export const submitQuery = `
query createServiceRequest($serviceRequest: createSrInputInput!) {
  createServiceRequest(inputDto: $serviceRequest) {
      id
      status
      subType
      type
      member{
      id
      },
      owner,
      description,
      summary,
      status
      additionalData
      audit{
          createdBy,
          createdOn,
          modifiedBy,
          modifiedOn
      }
  }
}
`;

export const submitUpdateQuery = `query updateServiceRequest($id: Long!, $requestObj: updateRequestInput!) {
  updateServiceRequest(id: $id, updateRequestObject: $requestObj) {
    data{
      type
         subType
         member{
             id       
         }
         subStatus
         owner
         description
         summary
         status
         additionalData
         audit{
             createdBy
             createdOn
               modifiedBy
               modifiedOn
         }
     }
     errors{
      code
      message}
 }}`;

export const getGiftingHistory = `query getSRByMemberIdAndType($memberId:UUID!, $type:String, $pageSize: Int!,$pageNo: Int!){
  getSRByMemberIdAndType(memberId:$memberId, type: $type,
  pageSize: $pageSize,
  pageNo: $pageNo){
    responseList{
        id
        type
        subType
        member{
            id
        }
        owner
        description
        summary
        status
        additionalData
        audit{
            createdBy
            createdOn
            modifiedBy
            modifiedOn
        }
    }
    pagingInfo{
      pageNumber
      pageSize
      totalRecords
    }
    errors{
        code
        message
        }
    }
}`;

export const searchSrDashboard = `query searchSR($srSearchRequestInput:searchServiceRequestInput!){
  searchSR(inputDto:$srSearchRequestInput){
    responseList{
        id
        type
        subType
        member{
            id
            }
        owner
        description
        summary
        status
        additionalData
        audit{
            createdBy
            createdOn
            modifiedBy
            modifiedOn
            }
        }
 
    pagingInfo{
      pageNumber
      pageSize
      totalRecords
        }
    }
}`;

export const srSearch = `query getServiceRequestById($id: Long!) {
    getServiceRequestById(id: $id) {
      id
      type
      subType
      claimId
      member {
        id
        membershipId
        tenantId
      }
      owner
      description
      summary
      status
      activities {
        id
        commandId
        type
        subType
        summary
        status
        priority
        channel
        templateId
        additionalData
        audit {
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
      }
      additionalData
      audit {
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;

export const srSearchByType = `query getServiceRequestsByType(
    $searchServiceRequestByTypeInput: searchServiceRequestInput!
  ) {
    getServiceRequestsByType(inputDto: $searchServiceRequestByTypeInput) {
      searchServiceRequestsOutputDtoList {
        activities {
          id
          commandId
          type
          subType
          summary
          status
          priority
          channel
          additionalData
        }
        id
        type
        subType
        member {
          id
          membershipId
        }
        owner
        description
        summary
        status
        additionalData
        audit {
          modifiedBy
          modifiedOn
          createdBy
          createdOn
        }
      }
      pagingObject {
        pageSize
        pageNumber
        totalPages
        totalRecords
      }
      errors {
        code
        message
      }
      warnings {
        message
      }
    }
  }
`;

export const createSR = `query createServiceRequest(
    $serviceRequest: createSrInputInput!
    $notificationParams: CommonNotificationRequestParamsInput!
  ) {
    createServiceRequest(
      inputDto: $serviceRequest
      notificationParams: $notificationParams
    ) {
      id
      status
      subType
      type
      member {
        id
        membershipId
      }
      owner
      description
      summary
      status
      activities {
        id
        commandId
        type
        subType
        summary
        status
        priority
        channel
        templateId
        additionalData
        audit {
          modifiedBy
          modifiedOn
          createdBy
          createdOn
        }
      }
      additionalData
      audit {
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
      errors {
        code
        message
      }
      warnings {
        message
      }
    }
  }
`;

export const updateSR = `query updateServiceRequest(
    $id: Long!
    $requestObj: updateRequestInput!
    $notificationParams: CommonNotificationRequestParamsInput!
  ) {
    updateServiceRequest(
      id: $id
      updateRequestObject: $requestObj
      notificationParams: $notificationParams
    ) {
      commandId
      status
      errors {
        code
        message
      }
      warnings {
        message
      }
    }
  }
`;

export const searchEvent = `query searchEvent($input: String!) {
    searchEvent(input: $input) {
      eventInternalId
      eventUid
      vendorEventUid
      eventName
      vendorId
      skipFieldUpdate
      isOnSale
      isPreSale
      status
      onSaleDate
      preSaleDate
      description
      createdAt
      updatedAt
      artists {
        artistId
        artistInternalId
        eventInternalId
        artistName
        artistUrl
        createdAt
        updatedAt
      }
      tags {
        categoryTagName
        categoryInternalId
        eventInternalId
        createdAt
        updatedAt
      }
      images {
        imageInternalId
        eventInternalId
        imageType
        imageUrl
        imageStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const searchPerformance = `query searchPerformance($input: String!) {
    searchPerformance(input: $input) {
      venues
      cities
      scheduledDateTime
      performances {
        performanceInternalId
        performanceUid
        eventUid
        venueUid
        vendorId
        vendorPerformanceId
        scheduledDateTime
        isOnSale
        isPreSale
        status
        indicativeAvailability
        saleCurrency
        minSalePriceBuyNow
        maxSalePriceBuyNow
        minSalePriceRrp
        maxSalePriceRrl
        minSalePriceStore
        maxSalePriceStore
        minSaleFees
        maxSaleFees
        priority
        createdAt
        updatedAt
        syncStatus
        options {
          optionInternalId
          performanceInternalId
          ticketOptionUid
          performanceUid
          seatingClass
          merchantUid
          priceTypeName
          priceCurrency
          salePriceBuyNow
          salePriceRrp
          salePriceStore
          salePriceFees
          isAvailableInStock
          isOnSale
          isPreSale
          totalAvailableQty
          contiguousSeatsQty
          availableSeatsLimitQty
          syncStatus
          createdAt
          updatedAt
        }
        images {
          imageInternalId
          eventInternalId
          imageType
          imageUrl
          imageStatus
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const resetPassword = `mutation forgotPassword($requestDto: ForgotPasswordInput!) {
    forgotPassword(input: $requestDto) {
      code
    }
  }
`;

export const resetWebEnable = `query restWebEnable(
    $memberId: UUID!
    $membershipId: String
    $ownerId: String!
  ) {
    restWebEnable(
      memberId: $memberId
      membershipId: $membershipId
      ownerId: $ownerId
    ) {
      command
      status
      error {
        code
        message
      }
    }
  }
`;

export const getTenantServicesMappings = `query getTenantServicesMappings($packageId: String) {
    getTenantServicesMappings(packageId: $packageId) {
      packageId
      tenantId
      serviceTypes {
        name
        serviceRequestTypeDTOS {
          id
          name
          allowedSubTypes
          isSubTypeMandatory
          serviceRequestAdditionalFields {
            name
            isMandatory
            possibleValues
            linkedValues
            linkedToField
          }
        }
      }
    }
  }
`;

export const getAllMetadata = `query getAllMetadata {
    getAllMetadata {
      id
      name
      allowedSubTypes
      serviceRequestAdditionalFields {
        name
        possibleValues
      }
      activityMetadata {
        id
        commandId
        type
        subType
        summary
        status
        priority
        channel
        additionalData
      }
      auditDTO {
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;

export const getMemberCashbackEligibility = `
 query cashbackEligibility(
  $memberId: UUID!
  $membershipId: String
  $channel: String
 ) {
  cashbackEligibility(
   memberId: $memberId
   membershipId: $membershipId
   channel: $channel
  ) {
   period {
    start
    end
   }
   rules {
    percentage
    allowedItemsPerBooking
    allowedBookingsPerYear
    cashBackCurrency
    excludedEventCategories
   }
   cashback {
    eligible
    totalCashback
    remainingBooking
   }
  }
 }
`;

export const getConversionRate = `
 query getCurrencyConversion($fromCurrency: String!, $toCurrency: String!) {
  geCurrencyConversionRate(
   fromCurrency: $fromCurrency
   toCurrency: $toCurrency
  )
 }
`;

export const getAllUsers = `
  query getAllUsers {
    getAllUsers {
      id
      userName
      email
    }
  }
`;

export const getTranscript = `
  query getTranscript($transcriptUrl: String!) {
    getTranscript(transcriptUrl: $transcriptUrl) {
      transcript
    }
  }
`;

export const partnerJump = `
  query partners(
    $memberId: String
    $partnerJumpType: String
    $packageId: String
    $packageRefId: String
    $accountNumber: String
    $sortCode: String
    $benefitId: String
    $membershipId: String
    $IsMemberImpersonation: String
    $UserImpersonator: String
    $BenefitName: String
  ) {
    partners(
      input: {
        memberId: $memberId
        partnerJumpType: $partnerJumpType
        packageId: $packageId
        packageRefId: $packageRefId
        accountNumber: $accountNumber
        sortCode: $sortCode
        benefitId: $benefitId
        membershipId: $membershipId
        attributes: {
          IsMemberImpersonation: $IsMemberImpersonation
          UserImpersonator: $UserImpersonator
          BenefitName: $BenefitName
        }
      }
    ) {
      url
      httpMethod
      parameters
    }
  }
`;

export const get_countries = `
  query getCountry {
    getCountries {
      name
      abbreviation
      twoLetterCode
      threeLetterCode
      threeDigitCode
      callingCode
      autoPlateCode
      internationalCallingCode
      states {
        name
        code
        abbreviation
      }
    }
  }
`;

export const get_metadata = `
  query getAllMetadata {
    getAllMetadata {
      id
      name
      allowedSubTypes
      serviceRequestAdditionalFields {
        name
        possibleValues
      }
      activityMetadata {
        id
        commandId
        type
        subType
        summary
        status
        priority
        channel
        additionalData
      }
      auditDTO {
        createdBy
        createdOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;

export const getGMClaimedOrderHistory = `
  query claimedOffers($pageNo: Int!, $pageSize: Int!, $offerType: OfferType) {
    claimedOffers(pageNo: $pageNo, pageSize: $pageSize, offerType: $offerType) {
      claimedOffers {
        id
        offerId
        externalId
        offerSource
        offerType
        claimType
        redemptionCode
        expiryDateTime
        claimDateTime
        byAgent
        attributes
        data {
          amount
          emailId
          firstName
          lastName
          address {
            city
            country
            phoneNumber
            postalCode
            postalCodeExt
            line1
            line2
            line3
            region
          }
          reFulfilledForClaimId
          srId
          reason
          additionalComments
        }
      }
      offers {
        id
        externalId
        offerSource
        offerType
        startDateTime
        endDateTime
        claimLimitsReached {
          user
          total
          userPerPeriod
          totalPerPeriod
          notEnoughCodes
        }
        redemptionDetails {
          method
          type
          url
          guidelines
        }
        images {
          classifier
          imageUrl
          altText
        }
        merchant {
          id
          name
          imageUrl
          altText
        }
      }
      pagingInfo {
        pageNumber
        pageSize
        totalResults
      }
    }
  }
`;

export const getImpersonateToken = `
  query impersonateToken($externalRefId: String!) {
    impersonateToken(externalRefId: $externalRefId) {
      accessToken
      expiresIn
      refreshToken
      refreshExpiresIn
      tokenType
      scope
    }
  }`;

export const getPaymentVehiclesDetails = `
  query getPaymentVehiclesDetails($idList: [ UUID ]!) {
  getPaymentVehiclesDetails(pvIdList: $idList) {
      paymentVehiclesDetails {
      paymentVehicleId
      dateCreated
      dateModified
      userModified
      userCreated
      paymentMethod
      paymentMethodDetails
      attributes
      status
        billingAddress {
        addressLine1
        addressLine2
        addressLine3
        city
        countryCode
        county
        email
        postalCode
        state
      }
    }
  }
}
`;

export const payment = `
  query payment($input: PaymentRequestInput!) {
  payment(PaymentRequest: $input) {
      response {
      billId
      transactionStatus
    }
    billId
    totalCashbackAmount
    currency
    success
    failureDescription
  }
}
`;

export const updatePayment = `
  query updatePayment($input: updatePaymentRequestInput!) {
  updatePayment(updatePaymentRequest: $input) {
    status
  }
}
`;

export const getfilteredGMGiftCards = `
  query filteredGiftCards(
    $redemptionMethod: GiftCardRedemptionMethod
    $pageNo: Int!
    $pageSize: Int!
  ) {
    filteredGiftCards(
      redemptionMethod: $redemptionMethod
      pageNo: $pageNo
      pageSize: $pageSize
    ) {
      claims {
        attributes
        byAgent
        claimDateTime
        data {
          address {
            city
            country
            line1
            line2
            line3
            phoneNumber
            postalCode
            postalCodeExt
            region
          }
          amount
          emailId
          firstName
          lastName
        }
        expiryDateTime
        externalId
        id
        offerId
        offerSource
        offerType
        redemptionCode
      }
      favourites {
        createdOn
        externalId
        offerId
        offerSource
      }
      offers {
        additionalDetails {
          key
          value
        }
        carouselCardTextColor
        claimConditionsFailed {
          dimension
          subDimension
          values
        }
        claimLimitsReached {
          notEnoughCodes
          total
          totalPerPeriod
          user
          userPerPeriod
        }
        comingSoonDetails {
          images {
            altText
            classifier
            imageUrl
          }
          shortTitle
          title
        }
        ctaDetails {
          key
          value
        }
        description
        endDateTime
        externalId
        headline
        id
        images {
          altText
          classifier
          imageUrl
        }
        keyTerms
        merchant {
          altText
          id
          imageUrl
          name
        }
        offerSource
        offerType
        preClaimAdvice
        redemptionDetails {
          guidelines
          method
          type
          url
        }
        showPreClaimPopup
        startDateTime
        teaser
        termsAndConditions
        termsAndConditionsUrl
        title
        titleShort
        valueRestrictions {
          exclusivelyAllowedValues
          maximum
          minimum
        }
        voucherCodeExpiryModelType
        weight
      }
      pagingInfo {
        pageNumber
        pageSize
        totalResults
      }
    }
  }
`;

export const getMemberRewardsInfo = `
  query memberAccountInfo {
    memberAccountInfo {
      capDollars
      capPoints
      currentTier
      redeemableDollars
      redeemablePoints
    }
  }
`;

export const getAdminSRDashboardSearch = `
  query searchSRDashboard(
    $searchServiceRequestInput: searchServiceRequestInput!
  ) {
    searchSRDashboard(srDashBoardInput: $searchServiceRequestInput) {
      searchServiceRequestDashBoardOutputDtoList {
        id
        type
        subType
        member {
          memberId
          membershipId
          memberNumber
          membershipNumber
          tenantId
        }
        owner
        description
        summary
        status
        subStatus
        additionalData
        audit {
          createdBy
          createdOn
          modifiedBy
          modifiedOn
        }
        errors {
          code
          message
        }
      }
      pagingObject {
        pageSize
        pageNumber
        totalPages
        totalRecords
      }
      errors {
        code
        message
      }
      warnings {
        message
      }
    }
  }
`;

export const getAllClaimedOffer = `query getAllClaimedOffer($tenantId: Long, $claimId: Long, $status: String, $claimType: String, $customerId: String, $userId: Long, $userGroupId: Long, $fromDate: String, $toDate: String, $offerType: String, $bookingReference: String, $transactionDate: String, $pageNumber: Int, $pageSize: Int) {
  getAllClaimedOffer(
    claimedOfferRequest: {tenantId: $tenantId, claimId: $claimId, claimType: $claimType, toDate: $toDate, fromDate: $fromDate, status: $status, customerId: $customerId, userId: $userId, userGroupId: $userGroupId, offerType: $offerType, bookingReference: $bookingReference, transactionDate: $transactionDate}
    paging: {pageNumber: $pageNumber, pageSize: $pageSize}
  ) {
    claimedOffers {
      id
      customerId
      userId
      userGroupId
      tenantId
      offerId
      externalId
      offerSource
      offerType
      redemptionCode
      expiryDateTime
      claimDateTime
      byAgent
      claimType
      status
      attributes
      isShared
      channel
      isCashbackApplicable
      data {
        address {
          line1
          line2
          line3
          city
          region
          postalCode
          postalCodeExt
          country
          phoneNumber
        }
        amount
        emailId
        firstName
        lastName
        contentProvider
        giftCardType
        quantity
        srId
        reason
        additionalComments
        reFulfilledForClaimId
        claimDetails {
          basketData {
            data {
              totalAmount
              transactionFees
              message
              seatInfo {
                admissionId
                seatId
                row
                seatNo
                aisle
                seatingClassID
                seatingClassName
                salePriceBuyNow
                salePriceFees
                amount
                ticketOptionSectionId
                ticketOptionSectionName
                ticketOptionMerchantUID
                ticketOptionName
                message
                voucherDetails {
                  id
                  code
                  active
                  expires
                  voucherType
                }
              }
              deliveryMethod {
                deliveryMethodId
                deliveryMethodDesc
                deliveryCost
                notes
              }
            }
            additionalDetails {
              bookingUrl
              tourId
              seriesName
              basketId
              slotTime
              controlData {
                session
                cookie
              }
              basketDetails {
                orderLines {
                  sku
                  qty
                  amount
                  title
                }
                totalAmount
              }
              venueDetails {
                venueId
                venueName
                city
                county
                country
                latitude
                longitude
              }
            }
            status
            vendorStatusCode
            vendorStatusMsg
            session
          }
          userDetails {
            firstName
            lastName
            email
            phoneNo
          }
          threeDsResponse {
            pvId
            billId
            checkEnrollResponse {
              acsUrl
              authenticationTransactionId
              paReq
            }
            transactionStatus
            redirectUrl
          }
          orderData {
            data {
              orderId
              totalAmount
              totalTickets
              transactionFees
              message
              seatInfo {
                admissionId
                seatId
                row
                seatNo
                aisle
                seatingClassID
                seatingClassName
                salePriceBuyNow
                salePriceFees
                amount
                ticketOptionSectionId
                ticketOptionSectionName
                ticketOptionMerchantUID
                ticketOptionName
                message
                voucherDetails {
                  id
                  code
                  active
                  expires
                  voucherType
                }
              }
              deliveryMethod {
                deliveryMethodId
                deliveryMethodDesc
                deliveryCost
                notes
              }
              voucherCode
              expiresOn
            }
            status
            vendorStatusCode
            vendorStatusMsg
            session
          }
          venueDetails {
            venueId
            venueName
            city
            county
            country
            latitude
            longitude
          }
          slotTime
          merchantName
          category
          eventName
        }
      }
    }
    pagingInfo {
      pageSize
      pageNumber
      totalResults
    }
  }
}`;

export const getMemberCahbackRemaingbooking =
  'query cashbackEligibility($memberId: UUID!, $membershipId: String, $channel: String) { cashbackEligibility(memberId: $memberId, membershipId: $membershipId, channel: $channel) { period { start end } rules { percentage allowedItemsPerBooking allowedBookingsPerYear cashBackCurrency excludedEventCategories } cashback { eligible totalCashback remainingBooking } } }';

export const linkTransactionWithSR = `query linkTransactionWithSR($srId:Long!,$claimId:Long!) {
  linkTransactionWithSR(
    srId:$srId, claimId:$claimId) {
    data
    }
}
`;

export const getServiceRequestByClaimId = `query getServiceRequestByClaimId($claimId:Long!, $pageNo: Int, $pageSize:Int) {
  getServiceRequestByClaimId(
    claimId:$claimId, pageNo:$pageNo, pageSize:$pageSize
  ) {
    responseList{
        id
        type
        subType
        member {
            id
            membershipId
        }
        owner
        description
        summary
        status
        subStatus
        activities {
            id
            commandId
            type
            subType
            summary
            status
            priority
            channel
            templateId
            additionalData
            audit {
                createdBy
                createdOn
                modifiedBy
                modifiedOn
            }
        }
        additionalData
        audit {
            createdBy
            createdOn
            modifiedBy
            modifiedOn
        }
        tenantId
        claimId
        userId
        userGroupId
    }
    pageNumber
    pageSize
    totalRecords
    totalPages
    sortBy
    sortOrder
    errors {
        code
        message
    }
    }
  }`;

export const getPaymentDetails = `query getPaymentDetails($srId: String, $memberId: UUID, $id: String, $claimId: String) {
  getPaymentDetails(
    paymentDetailRequest: {srId: $srId, memberId: $memberId, id: $id, claimId: $claimId}
  ) {
    status
    error
    paymentDetails {
      requestId
      relatedPaymentId
      amount
      status
      transactionType
      flowType
      createdBy
      createdDate
      lastProcessedDate
    }
  }
}`;

export const cashbackAmountEstimation = `query cashbackAmountEstimation($cashbackAmountEstimationRequest:CashbackAmountEstimationRequestInput!) {
    cashbackAmountEstimation(
      cashbackAmountEstimationRequest:$cashbackAmountEstimationRequest) {
        totalCashbackAmount
        extraCashbackAmount
        cashbackPercentage
        currencyConversionRate
        eligible
        }
  }`;

export const cashbackByClaimId = `query cashbackByClaimId($claimId: String!) {
  cashbackByClaimId(claimId: $claimId) {
    cashbackPercentage
    cashbackAmount
    totalCashback
    priceAfterCashback
  }
}`;
