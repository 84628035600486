import {Box, Button, DialogContent} from '@mui/material';
import LogoutBackdrop from 'Components/Header/LogoutBackdrop';
import DialogWrapper from 'Pages/Tabs/Gifting/components/dialogs/DialogWrapper';
import {fetchMemberDetails} from 'Redux/Slices/selectedMember';
import {resetWebEnable} from 'Redux/Slices/serviceRequest';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';

const ResetWebenable = (props) => {
  const {openResetWebenableDialog, isResetWebenableDialog} = props;

  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const currentMemberData = selectedMember.currentMemberDetails;
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const callResetWebenableApi = () => {
    let variable = {
      memberId: currentMemberData?.memberId,
      membershipId: selectedMember?.membershipId,
      ownerId: ownerID.toLowerCase(),
    };
    resetWebenableApiCall(variable);
  };

  const resetWebenableApiCall = async (variable) => {
    try {
      setLoading(true);
      let resp = await dispatch(resetWebEnable(variable)).unwrap();
      if (resp.data.restWebEnable[0]?.status.toUpperCase() === 'SUCCESS') {
        callMemberDetailsApi();

        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
          }),
        );
        closeModal('');
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      if (error) {
        closeModal('');
        setLoading(false);
      }
    }
  };

  const callMemberDetailsApi = () => {
    const variable = {
      memberId: currentMemberData?.memberId,
      membershipId: selectedMember?.membershipId ?? currentMemberData?.memberId,
    };

    memberDetailsApiCall(variable);
  };

  const memberDetailsApiCall = async (variable) => {
    try {
      await dispatch(fetchMemberDetails({variable})).unwrap();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const closeModal = (e) => {
    isResetWebenableDialog && isResetWebenableDialog(e);
  };
  if (loading) return <LogoutBackdrop text="Loading..." />;
  return (
    <DialogWrapper closeDialogMethod={isResetWebenableDialog} open={openResetWebenableDialog}>
      <DialogContent>
        <Box sx={{paddingTop: '5px'}}>
          Are you sure you want to reset the webenable for this customer?
        </Box>
        <Button onClick={callResetWebenableApi}>Yes</Button>
        <Button onClick={closeModal}>No</Button>
      </DialogContent>
    </DialogWrapper>
  );
};

export default ResetWebenable;
