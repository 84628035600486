import React, {useEffect, useState} from 'react';
import Form from 'Components/Form/FormWrapper';
import {buttonList, serviceRequestDetailsSection} from './enquirySR';
import {createValidationSchema, initialValues} from './enquirySRSchema';
import {useDispatch, useSelector} from 'react-redux';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {getCurrency, stringAscSort, updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {SR_STATUS, UPDATE_SR_ERROR} from 'Utils/constants/genericConstants';
import {getSolicitationId} from 'Utils/helpers/MemberDetails';
import {createSR, updateSR} from 'Redux/Slices/serviceRequest';
import {SnackBar} from 'Components';
import {useSnackBar} from 'Hooks';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import LogoutBackdrop from 'Components/Header/LogoutBackdrop';
import {isDisableFormFields} from 'Utils/helpers/SRListHelpers';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';

interface IProps {
  srFormDetails?: any;
  closeDialogMethod?: any;
}

const EnquirySR: React.FC<IProps> = (props) => {
  const {
    srFormDetails: {srType},
  } = props;

  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const selectedSRData = useSelector((state: any) => state.selectedSR.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const mapping = useSelector((state: any) => state?.TenantServicesMappings?.mapping);

  const dispatch = useDispatch();
  const [formValues, setFormValues]: any = useState(initialValues);
  const [section1FormContent, setSection1FormContent] = useState(serviceRequestDetailsSection);
  const [buttonSection, setButtonSection] = useState(buttonList);
  const [additionalFields, setAdditionalFields] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [srStatus, setSrStatus] = useState('');
  //const [srSubType, setSrSubType] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFormContentSet, setIsFormContentSet] = useState(false);

  useEffect(() => {
    setSREnquiryMetaData();
  }, [srType, mapping]);

  useEffect(() => {
    setFormContentValues();
    setIsFormContentSet(!isFormContentSet);
  }, [additionalFields, selectedSRData, srType]);

  useEffect(() => {
    if (selectedSRData && selectedSRData.additionalData) {
      let {additionalData} = selectedSRData;
      let srFormValues = {
        summary: selectedSRData?.summary,
        subtype: selectedSRData?.subType,
        reason: additionalData?.reason,
        channel: additionalData?.channel || 'Form',
        merchantName: additionalData?.merchantName || '',
        orderNumber: additionalData?.orderNumber || '',
        transactionAmount: additionalData?.transactionAmount,
        transactionDate: dateConversionIsoToLocal(
          additionalData?.transactionDate,
          null,
          'YYYY-MM-DD',
        ),
        itemPurchased: additionalData?.itemPurchased || '',
        paymentMethod: additionalData?.paymentMethod || '',
        owner: selectedSRData?.owner?.id || '',
        resolutionDesc: additionalData?.resolutionDesc,
        comments: additionalData?.comments,
        buttonAction: '',
      };
      setSrStatus(selectedSRData?.status);
      //setSrSubType(selectedSRData?.subType);
      setFormValues(srFormValues);
      setError('');
    } else {
      setSrStatus('');
      //setSrSubType('');
      setError('');
      setFormValues(initialValues);
    }
  }, [selectedSRData, isFormContentSet, props.srFormDetails]);

  const setSREnquiryMetaData = () => {
    try {
      const enquiryMetaData = mapping?.serviceTypes
        ?.find((d: any) => d.name === 'General')
        ?.serviceRequestTypeDTOS?.find((sr: any) => srType === sr?.name);

      const additionalFields: any[] = enquiryMetaData?.serviceRequestAdditionalFields.slice() || [];

      additionalFields.push({
        name: 'subType',
        possibleValues: enquiryMetaData?.allowedSubTypes || [],
      });

      setAdditionalFields(additionalFields);
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const setFormContentValues = () => {
    setLoading(true);

    let srSubTypesArray = (
      additionalFields.find((f) => f.name === 'subType')?.possibleValues ||
      props?.srFormDetails.srSubType ||
      []
    )
      .sort(stringAscSort)
      .map((subType) => {
        return {value: subType, text: subType};
      });

    let srOwnerListArray = (props?.srFormDetails?.srOwnerList || []).map((item) => {
      return {value: item.id, text: item.userName};
    });

    let targetFields = {
      subtype: {
        targetAttribute: 'options',
        targetValue: srSubTypesArray,
      },
      if(additionalData) {
        targetFields['channel'] = {
          targetAttribute: 'options',
          targetValue: [{value: additionalData?.Channel, text: additionalData?.Channel}],
        };
      },
      merchantName: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Merchant Name ',
      },
      orderNumber: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Order Number ',
      },
      owner: {
        targetAttribute: 'options',
        targetValue: srOwnerListArray,
      },
      transactionAmount: {
        targetAttribute: 'fieldLabel',
        targetValue: 'Transaction Amt ' + tenantConfig?.currencySymbol,
      },
    };
    additionalFields.forEach((field) => {
      targetFields[field.name] = {
        targetAttribute: 'options',
        targetValue: field.possibleValues?.map((option: any) => ({
          value: option,
          text: option,
        })),
      };
    });

    let targetButtonFields = {
      saveButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      pendingButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      closeButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      cancelButton: {
        targetAttribute: 'isDisabled',
        targetValue: false,
      },
      reopenButton: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
    };
    if (
      selectedSRData?.id &&
      (selectedSRData.status === SR_STATUS.CLOSED || selectedSRData.status === SR_STATUS.CANCELLED)
    ) {
      targetButtonFields = {
        saveButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        pendingButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        closeButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        cancelButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        reopenButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };
    } else if (selectedSRData?.id && selectedSRData.status === SR_STATUS.PENDING) {
      targetButtonFields = {
        saveButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        pendingButton: {
          targetAttribute: 'isDisabled',
          targetValue: true,
        },
        closeButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        cancelButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
        reopenButton: {
          targetAttribute: 'isDisabled',
          targetValue: false,
        },
      };
    }

    let updatedFormContent = updateFormContent(section1FormContent, targetFields);
    setSection1FormContent(updatedFormContent);
    let updateButtonList = updateFormContent(buttonList, targetButtonFields);
    setButtonSection(updateButtonList);

    if (
      selectedSRData?.id &&
      (selectedSRData.status === SR_STATUS.CLOSED || selectedSRData.status === SR_STATUS.CANCELLED)
    ) {
      let disabledFormContent = updateFormContent(
        section1FormContent,
        isDisableFormFields(section1FormContent.fields, true),
      );
      setSection1FormContent(disabledFormContent);
    } else {
      let disabledFormContent = updateFormContent(
        section1FormContent,
        isDisableFormFields(section1FormContent.fields, false),
      );
      setSection1FormContent(disabledFormContent);
    }

    setLoading(false);
  };

  const handleOnSubmit = (values, action) => {
    (async () => {
      let status = srStatus || SR_STATUS.OPEN;
      if (values?.buttonAction) {
        switch (values?.buttonAction) {
          case 'Save':
            status = srStatus || SR_STATUS.OPEN;
            break;
          case 'Pending':
            status = SR_STATUS.PENDING;
            break;
          case 'Close':
            status = SR_STATUS.CLOSED;
            break;
          case 'Cancel':
            status = SR_STATUS.CANCELLED;
            break;
          case 'Reopen':
            status = SR_STATUS.OPEN;
            break;
        }
      }
      let {serviceType} = props.srFormDetails;

      const notificationParams = {
        firstName: selectedMember?.currentMemberDetails?.firstName,
        lastName: selectedMember?.currentMemberDetails?.lastName,
        membershipNumber: selectedMember?.membershipNumber,
        toEmail:
          selectedMember?.currentMemberDetails?.primaryEmail ||
          selectedMember?.currentMemberDetails?.clientEmail,
        packageId: selectedMember?.packageId,
        solicitationId: getSolicitationId(selectedMember),
        externalRefId: selectedMember?.currentMemberDetails?.externalRefId,
        membershipEnabled: tenantConfig.membershipEnabled,
      };

      if (selectedSRData?.id) {
        let updateSRVariable = {
          id: selectedSRData?.id,
          requestObj: {
            commands: [
              {
                commandName: 'updateSR',
                commandId: 'updateSR2',
                type: props.srFormDetails.srType,
                subType: values.subType,
                description: 'description',
                summary: values.summary,
                status: status || SR_STATUS.OPEN,
                owner: {
                  id: values.owner,
                },
                additionalData: {
                  source: 'ng-engage',
                  externalRefId: selectedMember?.currentMemberDetails?.externalRefId,
                  reason: values.reason,
                  channel: values.channel,
                  merchantName: values.merchantName,
                  orderNumber: values.orderNumber,
                  transactionAmount: values.transactionAmount,
                  transactionDate: values.transactionDate,
                  itemPurchased: values.itemPurchased,
                  paymentMethod: values.paymentMethod,
                  resolutionDesc: values.resolutionDesc,
                  comments: values.comments,
                  service: serviceType,
                  currency: getCurrency(selectedMember),
                },
                audit: {
                  modifiedBy: ownerID?.toLowerCase(),
                  modifiedOn: selectedSRData.audit?.modifiedOn,
                },
              },
            ],
          },
          notificationParams,
        };
        try {
          let resp = await dispatch(updateSR(updateSRVariable)).unwrap();
          let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
          if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
            let payload = {
              id: selectedSRData?.id,
            };
            await dispatch(searchSRByID(payload)).unwrap();
            dispatch(
              isUpdateSRList({
                isRefreshSRList: true,
              }),
            );
            setError('');
            values?.buttonAction !== 'Reopen' && props.closeDialogMethod(false);
          } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
            let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors;
            setError(srUpdateErrors);
          } else console.log('Something Went Wrong');
        } catch (error: any) {
          setError(UPDATE_SR_ERROR);
        }
      } else {
        let apiVariable = {
          serviceRequest: {
            id: null,
            type: srType,
            subType: values.subType,
            member: {
              id: selectedMember?.currentMemberDetails?.memberId,
              membershipId: selectedMember?.membershipId
                ? selectedMember?.membershipId
                : selectedMember?.currentMemberDetails?.memberId,
              // membershipNumber: selectedMember?.membershipNumber || null,
            },
            userId: selectedMember?.currentMemberDetails?.userId || null,
            userGroupId: selectedMember?.membershipId || null,
            owner: {
              id: values.owner,
            },
            description: 'description',
            summary: values.summary,
            status: status || SR_STATUS.OPEN,

            additionalData: {
              source: 'ng-engage',
              externalRefId: selectedMember?.currentMemberDetails?.externalRefId,
              reason: values.reason,
              channel: values.channel,
              merchantName: values.merchantName,
              orderNumber: values.orderNumber,
              transactionAmount: values.transactionAmount,
              transactionDate: values.transactionDate,
              itemPurchased: values.itemPurchased,
              paymentMethod: values.paymentMethod,
              resolutionDesc: values.resolutionDesc,
              comments: values.comments,
              service: serviceType,
              currency: getCurrency(selectedMember),
            },
            audit: {
              createdBy: ownerID.toLowerCase(),
            },
          },
          notificationParams,
        };

        let resp = await dispatch(createSR(apiVariable)).unwrap();
        if (resp) {
          dispatch(
            isUpdateSRList({
              isRefreshSRList: true,
            }),
          );
          props.closeDialogMethod(false);
        }
      }
    })();
  };

  // let customEvents = {
  //   subtype: {
  //     onChange: (e) => {
  //       setSrSubType(e?.target?.value);
  //     },
  //   },
  // };

  return (
    <>
      <Form
        onSubmit={handleOnSubmit}
        validationSchema={createValidationSchema(props.srFormDetails?.isSubTypeMandatory)}
        initialValues={formValues}
        section1={section1FormContent}
        //customEvents={customEvents}
        buttonStack={buttonSection}
      />
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
      />
      {loading && <LogoutBackdrop text="Logging out" />}
    </>
  );
};
export default EnquirySR;
