import {
  HAPPY_TO_BOOK_ACTIVITY_STATUS,
  ISSUE_PAYMENT_ACTIVITY_STATUS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_TYPE,
  PAYMENT_STATUS_CAPS,
  SR_TYPES,
} from 'Utils/constants/genericConstants';
import {getSolicitationId} from './MemberDetails';
import {getCurrency} from './SRDetailViewHelper';

export const cardNumberFormatter = (cardNumber) => {
  if (cardNumber) {
    return cardNumber.substring(cardNumber.length - 4);
  }
  return '';
};

export const convertToTitleCase = (sentence) => {
  if (sentence?.length) return `${sentence.charAt(0).toUpperCase()}${sentence.slice(1)}`;
  else return '';
};

export const filterObj = (flaggedKeys: string[], obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (!flaggedKeys.includes(key)) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const createChargePaymentRequest = (
  formValues,
  selectedPvId,
  selectedSRDetails,
  currentMember,
  memberData,
  userInfo,
) => {
  return {
    input: {
      serviceRequestDetails: {
        serviceRequestId: selectedSRDetails?.id?.toString(),
        serviceRequestType: selectedSRDetails?.type,
        serviceRequestAddDate: selectedSRDetails?.audit?.createdOn,
      },
      memberDetails: {
        firstName: currentMember?.firstName,
        lastName: currentMember?.lastName,
        dob: currentMember?.dateOfBirth,
        street1: currentMember?.addressLine1,
        street2: currentMember?.addressLine2,
        city: currentMember?.city,
        state: currentMember?.state,
        country: currentMember?.countryCode,
        phoneNumber:
          currentMember?.mobilePhone || currentMember?.homephone || currentMember?.businessphone,
        postalCode: currentMember?.postalCode,
        externalClientRef1: currentMember?.externalRefId,
        memberJoinDate: currentMember?.joinDate,
        memberId: currentMember?.memberId,
      },
      membershipDetails: {
        memberShipNumber: memberData?.membershipNumber,
        solicitationId: getSolicitationId(memberData),
        packageId: memberData?.packageId,
      },
      paymentDetails: {
        realTime: formValues.paymentMethod === PAYMENT_METHOD.BANK ? false : true,
        paymentVehicleObjectId: selectedPvId,
        currency: selectedSRDetails?.additionalData?.currency || getCurrency(memberData),
        amount: formValues.amount,
        payByCheckFlag: false,
        transactionReason: formValues.transactionReason,
        packageName: memberData?.packageName,
        benefitName: null,
        flowType: formValues.flowType,
        pvType: PAYMENT_METHOD_TYPE[formValues.paymentMethod],
        comments: formValues.comments,
      },
      audit: {
        user: userInfo,
      },
      eftDescriptor: formValues.eftDescription,
    },
  };
};

export const removePaymentAdditionalData = (paymentsAdditionalData, savedPaymentIndex) => {
  return filterObj([savedPaymentIndex], paymentsAdditionalData);
};

export const removeSavedPayment = (savedPaymentIndex, srDetails) => {
  let updatedSRDetails = Object.assign({}, srDetails);
  let savedPaymentArray = updatedSRDetails.additionalData?.savedPayments || [];
  let paymentsAdditionalData = updatedSRDetails.additionalData?.paymentsAdditionalData || {};

  paymentsAdditionalData = removePaymentAdditionalData(paymentsAdditionalData, savedPaymentIndex);

  let filteredPayments = savedPaymentArray.filter((payment) => {
    return payment.paymentIndex !== savedPaymentIndex;
  });
  updatedSRDetails.additionalData = {
    ...updatedSRDetails.additionalData,
    savedPayments: filteredPayments,
    paymentsAdditionalData: paymentsAdditionalData,
  };
  return updatedSRDetails;
};

export const createIssuePaymentActivity = (
  paymentActivityId,
  status,
  userInfo,
  paymentVehicleId,
) => {
  let additionalData = {
    pvId: paymentVehicleId,
    paymentActivityId,
  };

  let audit = {
    createdBy: userInfo,
    modifiedBy: userInfo,
  };

  const data = {
    type: 'Issue Payment',
    priority: 'Medium',
    status:
      ISSUE_PAYMENT_ACTIVITY_STATUS[status] || ISSUE_PAYMENT_ACTIVITY_STATUS['Transaction Error'],
  };

  let activityPayload = {
    commandName: 'addActivity',
    commandId: 'New Activity',
    ...data,
    additionalData: additionalData,
    audit: audit,
  };

  return activityPayload;
};

export const isEligibleForIssuePayment = (selectedSrDetails) => {
  const {type} = selectedSrDetails;
  if (
    type === SR_TYPES.BOOK_CASHBACK_EVENT ||
    type === SR_TYPES.CINEMA_ECODE ||
    type === SR_TYPES.FILM_RENTAL_ECODE ||
    type === 'OnSale Registration' ||
    type?.toUpperCase() === SR_TYPES.BOOK_EVENT.toUpperCase()
  ) {
    return true;
  }
  return false;
};

export const updateIssuePaymentActivity = (
  activity,
  paymentActivityId,
  status,
  userInfo,
  paymentVehicleId,
) => {
  let additionalData = {
    pvId: paymentVehicleId,
    paymentActivityId: paymentActivityId,
  };
  let audit = {...activity.audit, modifiedBy: userInfo};

  const data = {
    type: 'Issue Payment',
    priority: 'Medium',
    status:
      ISSUE_PAYMENT_ACTIVITY_STATUS[status] || ISSUE_PAYMENT_ACTIVITY_STATUS['Transaction Error'],
  };

  let activityPayload = {
    commandName: 'updateActivity',
    commandId: 'Command1',
    id: activity.id,
    ...data,
    additionalData: additionalData,
    audit: audit,
  };
  return activityPayload;
};

export const isEligibleForHappyToBookActivity = (selectedSrDetails) => {
  const {type} = selectedSrDetails;
  if (
    type === SR_TYPES.BOOK_CASHBACK_EVENT ||
    // type === SR_TYPES.CINEMA_ECODE ||
    // type === SR_TYPES.FILM_RENTAL_ECODE ||
    type?.toUpperCase() === SR_TYPES.BOOK_EVENT.toUpperCase()
  ) {
    return true;
  }
  return false;
};

export const updateHappyToBookActivity = (activity, status, userInfo, paymentVehicleId) => {
  let additionalData = {
    pvId: paymentVehicleId,
  };
  let audit = {...activity.audit, modifiedBy: userInfo};

  const data = {
    type: 'Happy to Book',
    status:
      HAPPY_TO_BOOK_ACTIVITY_STATUS[status] || HAPPY_TO_BOOK_ACTIVITY_STATUS['Transaction Error'],
  };

  let activityPayload = {
    commandName: 'updateActivity',
    commandId: 'Command2',
    id: activity.id,
    ...data,
    additionalData: additionalData,
    audit: audit,
  };
  return activityPayload;
};

export const managePaymentActivity = (
  selectedSrDetails,
  paymentActivityId,
  status,
  userInfo,
  paymentVehicleId,
  isPaymentSelected = false,
  oldPaymentIndex,
) => {
  const {activities} = selectedSrDetails;
  let activitiesTobeUpdated: any = [];
  let issuePaymentActivity;

  if (isEligibleForIssuePayment(selectedSrDetails)) {
    let activityIndexWithpaymentActivityId = -1;

    if (oldPaymentIndex) {
      activityIndexWithpaymentActivityId = activities.findIndex((activity) => {
        return activity?.additionalData?.paymentActivityId === oldPaymentIndex;
      });
    }

    if (activityIndexWithpaymentActivityId === -1) {
      if (!isPaymentSelected) {
        issuePaymentActivity = createIssuePaymentActivity(
          paymentActivityId,
          status,
          userInfo,
          paymentVehicleId,
        );
      }
    } else {
      issuePaymentActivity = updateIssuePaymentActivity(
        activities[activityIndexWithpaymentActivityId],
        paymentActivityId,
        status,
        userInfo,
        paymentVehicleId,
      );
    }
    if (issuePaymentActivity) {
      activitiesTobeUpdated.push(issuePaymentActivity);
    }
  }

  if (isEligibleForHappyToBookActivity(selectedSrDetails)) {
    let happyToBookActivity;

    let activityIndex = activities.findIndex((activity) => {
      return activity?.type === 'Happy to Book';
    });

    if (activityIndex !== -1) {
      happyToBookActivity = updateHappyToBookActivity(
        activities[activityIndex],
        status,
        userInfo,
        paymentVehicleId,
      );
      activitiesTobeUpdated.push(happyToBookActivity);
    }
  }
  return activitiesTobeUpdated;
};

export const createPaymentAdditionalData = (billId, srData, formValues) => {
  let updatedSRData = Object.assign({}, srData);
  let paymentsAdditionalData = Object.assign(
    {},
    srData?.additionalData?.paymentsAdditionalData || {},
  );

  paymentsAdditionalData[billId] = {
    comments: formValues.comments,
  };

  updatedSRData.additionalData['paymentsAdditionalData'] = paymentsAdditionalData;

  return updatedSRData;
};

export const isSavedPayment = (selectedSRDetails, selectedPaymentIndex) => {
  let savedPayments = selectedSRDetails?.additionalData?.savedPayments;
  if (!savedPayments || savedPayments.length === 0) {
    return false;
  }
  let isSavedPayment = false;
  savedPayments.forEach((payment) => {
    if (
      payment.paymentIndex === selectedPaymentIndex &&
      payment.billStatus.toUpperCase() === PAYMENT_STATUS_CAPS.NEW
    ) {
      isSavedPayment = true;
    }
  });
  return isSavedPayment;
};

export const isDuplicateCard = (cardnumber, paymentData) => {
  let existingCard = (paymentData || []).findIndex((item) => {
    return item.paymentMethodDetails.cardNumber === cardnumber;
  });
  if (existingCard !== -1) {
    return true;
  }
  return false;
};

export const getCountriesOptionValue = (countriesList) => {
  let countriesOptionValue = (countriesList || []).map((country) => {
    return {value: country.threeDigitCode, text: country.name};
  });
  return countriesOptionValue;
};

export const isViolatesNumberConstraint = (value) => {
  if (!value) {
    return false;
  }

  let numbers = value.match(/\d/g) || [];
  return numbers.length > 12;
};

export const isExpiryDateValid = (expiryDateYear, expiryDateMonth) => {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1;
  expiryDateMonth = Number(expiryDateMonth);
  expiryDateYear = Number(expiryDateYear);
  if (
    (expiryDateMonth >= currentMonth && expiryDateYear === currentYear) ||
    expiryDateYear > currentYear
  ) {
    return true;
  }
  return false;
};

export const isExpiryYearValid = (expiryDateYear) => {
  let currentYear = new Date().getFullYear();
  if (expiryDateYear < currentYear + 100) {
    return true;
  } else {
    return false;
  }
};
