/* eslint-disable no-unused-vars */
import {TextField, useTheme} from '@mui/material';
import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker, DesktopDateTimePicker} from '@mui/x-date-pickers';
import ClearInputIcon from 'Components/InputAdornments/ClearInputIcon';
import localeMap from 'Utils/helpers/LocaleMap';
import globalSpacing from 'Theme/Spacing';
import {useSelector} from 'react-redux';

interface DatePickerInputProps extends React.HTMLAttributes<HTMLElement> {
  'data-testid'?: string;
}

interface IProps {
  date?: Date | null;
  handleChange: any;
  label?: string;
  flex?: number;
  minDate?: any;
  position?: 'end' | 'start' | undefined;
  reset?: any;
  inline?: boolean;
  disabled?: boolean;
  showTime?: boolean; // New prop to control time display
}

const CustomDatePicker: React.FC<IProps> = (props) => {
  const {
    date,
    handleChange,
    label,
    flex = 1,
    minDate = null,
    position = 'end',
    reset,
    inline,
    disabled = false,
    showTime = false, // Default to false
  } = props;
  const theme = useTheme();
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[tenantConfig?.locale]}>
      {showTime ? (
        <DesktopDateTimePicker
          inputProps={{'data-testid': 'date-picker'} as DatePickerInputProps}
          label={label}
          value={date}
          onChange={handleChange}
          PopperProps={{
            sx: {
              '.MuiDayPicker-weekDayLabel': {
                color: theme.palette.text.primary,
              },
            },
          }}
          maxDate={Date.now()}
          InputAdornmentProps={{position: position}}
          {...(minDate && {minDate: Date.parse(minDate)})}
          {...(position === 'start' &&
            date && {
              InputProps: {
                endAdornment: <ClearInputIcon reset={reset} />,
              },
            })}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{
                shrink: true,
                sx: {
                  fontSize: 17,
                  fontWeight: 'bold',
                  color: theme.palette.text.primary,
                  '&.MuiInputLabel-shrink.Mui-disabled': {
                    color: theme.palette.text.disabled,
                  },
                },
              }}
              {...params}
              sx={{
                display: 'flex',
                flex: flex,
                marginRight: inline ? globalSpacing.s : null,
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
                borderRadius: `${theme.shape.borderRadius}px`,
                '& .MuiInputBase-root': {
                  pointerEvents: 'none',
                },
                '& .MuiInputBase-root button': {
                  pointerEvents: 'all',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: theme.palette.background.paper,
                  '& fieldset': {
                    borderColor: theme.palette.text.disabled,
                  },
                  '&.Mui-focused': {
                    '& fieldset': {
                      borderColor: theme.palette.action.active,
                    },
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.primary,
                  maxWidth: '70%',
                  '&.MuiInputLabel-shrink': {
                    color: theme.palette.text.primary,
                  },
                },
              }}
            />
          )}
        />
      ) : (
        <DesktopDatePicker
          inputProps={{'data-testid': 'date-picker'} as DatePickerInputProps}
          label={label}
          value={date}
          disabled={disabled}
          onChange={handleChange}
          PopperProps={{
            sx: {
              '.MuiDayPicker-weekDayLabel': {
                color: theme.palette.text.primary,
              },
            },
          }}
          maxDate={Date.now()}
          InputAdornmentProps={{position: position}}
          {...(minDate && {minDate: Date.parse(minDate)})}
          {...(position === 'start' &&
            date && {
              InputProps: {
                endAdornment: <ClearInputIcon reset={reset} />,
              },
            })}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{
                shrink: true,
                sx: {
                  fontSize: 17,
                  fontWeight: 'bold',
                  color: theme.palette.text.primary,
                  '&.MuiInputLabel-shrink.Mui-disabled': {
                    color: theme.palette.text.disabled,
                  },
                },
              }}
              {...params}
              sx={{
                display: 'flex',
                flex: flex,
                marginRight: inline ? globalSpacing.s : null,
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
                borderRadius: `${theme.shape.borderRadius}px`,
                '& .MuiInputBase-root': {
                  pointerEvents: 'none',
                },
                '& .MuiInputBase-root button': {
                  pointerEvents: 'all',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: theme.palette.background.paper,
                  '& fieldset': {
                    borderColor: theme.palette.text.disabled,
                  },
                  '&.Mui-focused': {
                    '& fieldset': {
                      borderColor: theme.palette.action.active,
                    },
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.primary,
                  maxWidth: '70%',
                  '&.MuiInputLabel-shrink': {
                    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                  },
                },
              }}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
