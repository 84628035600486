import {Box, Typography} from '@mui/material';
import {SnackBar, Wrapper} from 'Components';
import Loading from 'Components/Loading';
import TableView from 'Components/TableView';
import {useSnackBar} from 'Hooks';
import {MEMBER_SEARCH} from 'Pages/SearchMember/searchConstants';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import globalSpacing from 'Theme/Spacing';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {SortOrder, TabPanelType} from 'Utils/enums';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {getTRDashboardListGirdData} from './TRDashboardListGridDataSource';
import {fetchMemberDetails, setCurrentMemberBenefitDetails} from 'Redux/Slices/selectedMember';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {resetReduxOnSearch} from 'Utils/helpers/searchHelper';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {getTenantServiceMapping} from 'Redux/Slices/TenantServicesMappings';
import {DASHBOARD} from 'Utils/constants/Paths';
import {SEARCH_NO_RESULTS_ERROR} from 'Utils/constants/genericConstants';
import {JsonToCsv} from 'Components';

const TRDashboardList = ({results}) => {
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const [loading, setLoading] = useState(false);

  const [currentSortCriteria, setCriteriaSort] = useState('membership');
  const [sortOrder, setSortOrder] = useState(SortOrder.DESCENDING);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const tempTableData: any = [];
    (results || []).forEach((tableData) => {
      tempTableData.push({
        membership: tableData?.customerId,
        id: tableData.id,
        claimType: tableData.claimType,
        status: tableData.status,
        //owner: tableData?.owner?.userName || '',
        createdDate: dateConversionIsoToLocal(
          tableData?.claimDateTime,
          FORMAT_TYPE.DATE_TIME,
          '',
          tenantConfig?.locale,
        ),
        offerType: tableData.offerType,
        //summary: tableData.summary,
        transactionData: tableData,
      });
    });

    let resp = sortTable(
      tempTableData,
      'membership',
      'membership',
      SortOrder.ASCENDING,
      tenantConfig?.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);

    setTableData(tempTableData);
  }, [results]);

  const goToDashboard = async (payload) => {
    setLoading(true);
    const variable = {
      memberId: payload?.customerId,
      membershipId: payload?.userGroupId ?? payload?.customerId,
    };
    const response = await dispatch(fetchMemberDetails({variable})).unwrap();
    if (response?.error) {
      setError(getErrorMsg(response.error));
    } else if (response) {
      dispatch(setCurrentMemberBenefitDetails(tenantConfig?.additionalBenefits || '[]'));
      resetReduxOnSearch(dispatch);
      dispatch(
        isUpdateSRList({
          isRefreshSRList: true,
          filterType: 'Recent SRs',
        }),
      );
      dispatch(getTenantServiceMapping({packageId: response.packageId}));
      navigate(DASHBOARD, {
        state: {tab: TabPanelType.transactionLogs, selectedClaim: payload},
      });
    } else {
      setError(SEARCH_NO_RESULTS_ERROR);
    }
  };

  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig?.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  if (loading) return <Loading isOpen={true} />;
  return (
    <Wrapper>
      <Box mb={globalSpacing.l} mt={globalSpacing.m}>
        <Typography variant="subtitle1">{MEMBER_SEARCH.multiple_search_results.heading}</Typography>
        {results?.length && <JsonToCsv jsonData={results} />}
        <TableView
          {...getTRDashboardListGirdData(tableData, SortOrder.DESCENDING, '', true)}
          onRowClick={(rowData) => {
            goToDashboard(rowData.transactionData);
          }}
          sortTable={onSortIconClick}
        />
      </Box>
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
        />
      )}
    </Wrapper>
  );
};

export default TRDashboardList;
